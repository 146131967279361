import React from "react";
import "./TableOldButton.css";
import { Pagination } from 'antd';


const TableOldButton = ({
  setcurrentPage,
  currentPage,
  // indexOfFirstPage,
  // indexOfLastPost,
  totalData,
  listPerpage,
  handleRowsPerPageChange,
}) => {
 

    const handlePageChange =(page)=>{
      setcurrentPage(page)
    }



  const handleSizeChange = (current, size) => {
    // console.log("Page size changed to", size);
    handleRowsPerPageChange(size);
  };


  return (
<div className="pagination-container" >
    <Pagination
      total={totalData}
      current={currentPage}
      pageSize={listPerpage}
      showSizeChanger
      pageSizeOptions={['10', '50', '100']}
      onChange={handlePageChange}
      onShowSizeChange={handleSizeChange}
      select={false}  
      className="custom-pagination"
      /> 
       </div>
  );
};

export default TableOldButton;
