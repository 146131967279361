import React, { useEffect, useState } from "react";
import { loadWeb3 } from "../API/contract";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { API } from "../API/Api";
import { UpdateAuth } from "../Redux/AuthSlice";
import { useNavigate } from "react-router-dom";
import {
  Abi_Main,
  Abi_USDT,
  ContractAddress_Main,
  ContractAddress_USDT,
} from "../API/ContractAddress";
import Spinner from "../Components/Loader/Spinner";

export default function Add_Fund() {
  const user = useSelector((state) => state.Auth.userId);
  const jwt = useSelector((state) => state.Auth.jwt_token);
  let [Amount, setAmount] = useState(0);
  let [connected, setconnected] = useState("Wallet is locked");
  let [loading, setloading] = useState(false);
  let [USDT, setUSDT] = useState(0);
  let [spiner, setspiner] = useState(false);
  const [Wallet_Address, setWallet_Address] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const CalculateValue = (val) => {
    const Addvalues = val;
    const Totalvalue = parseFloat(Addvalues) + parseFloat(Amount);
    setAmount(Totalvalue);
  };

  const GetUserDetails = async () => {
    try {
      let responce = await API?.get(`userDetails?uid=${user}`, {
        headers: {
          Authorization: `${jwt}`,
          "Content-Type": "application/json", // Adjust the content type as needed
        },
      });
      responce = responce.data.data[0][0];
      // console.log("getUserDetails", responce);

      setWallet_Address(responce.btcaddress);
    } catch (e) {
      //  console.log("userDetails error", e);
      // console.log("userDetails error", e.response.data);
      if (
        e.response.data.showableMessage ==
        "Please login to access this resource"
      ) {
        dispatch(UpdateAuth({ isAuth: false, userId: null, jwt_token: null }));
        navigate("/");
      }
    }
  };

  const USDTBalance = async () => {
    try {
      let acc = await loadWeb3();
      let contract = await new window.web3.eth.Contract(
        Abi_USDT,
        ContractAddress_USDT
      );
      let result = await contract.methods.balanceOf(acc.toString()).call();
      // console.log("Result ",result.toString());
      result = parseFloat(result) / parseFloat(1000000000000000000);
      // console.log("Result ", result);
      //result = window.web3.utils.fromWei(result);
      setUSDT(result.toFixed(2));
    } catch (error) {
      setloading(false);
      console.log(error);
    }
  };

  const walletConnected = async () => {
    try {
      let acc = await loadWeb3();

      if (acc == "No Wallet") {
        setconnected("Wallet is locked");
      } else if (acc == "Wrong Network") {
        toast.error(" Wrong Network Please Connect BSC Network");
        setconnected("Wrong Network");
      } else {
        setconnected("Wallet is Connected");
      }
    } catch (e) {
      console.log("Error While WalletConnect", e);
    }
  };

  const BuyToken = async (e) => {
    setspiner(true);
    let acc = await loadWeb3();
    if (acc === "No Wallet" || acc === undefined) {
      toast.error("No Wallet Connected");
    } else if (acc == "Wrong Network") {
      //toast.error("Wrong Network Please Connect BSC Scan Network");
      console.log("Wrong Network Please Connect Polygon Scan Network");
    } else {
      // alert("acc" + acc);
      // alert("Wallet_Address" + Wallet_Address);
      if (Wallet_Address.toUpperCase() == acc.toUpperCase()) {
        if (Amount !== 0) {
          if (USDT < Amount) {
            toast.error("Insufficient USDT Balance");
          } else {
            setspiner(true);

            let amountValue = window.web3.utils.toWei(Amount.toString()); // parseFloat(Amount) * 1000000000000000000; // window.web3.utils.fromWei(Amount.toString());
            const gasPrice = await window.web3.eth.getGasPrice();
            const approveBlock = window.web3.eth.getBlock("latest");

            try {
              let contract_Main = await new window.web3.eth.Contract(
                Abi_Main,
                ContractAddress_Main
              );
              let contract_USDT = await new window.web3.eth.Contract(
                Abi_USDT,
                ContractAddress_USDT
              );

              let approveCall = await contract_USDT.methods
                .approve(ContractAddress_Main, amountValue)
                .send({
                  from: acc,
                  gasLimit: approveBlock.gasLimit,
                  gasPrice: await window.web3.eth.getGasPrice(),
                });
              toast.success("Approved Succesful");
              let sellCall = await contract_Main.methods
                .deposit(amountValue)
                .send({
                  from: acc,
                  gasLimit: approveBlock.gasLimit,
                  gasPrice: await window.web3.eth.getGasPrice(),
                });
              let res = await API.post(
                "Deposite_FundByContract",
                {
                  uid: user,
                  addreslist: acc,
                  usd: Amount,
                  txn: sellCall.transactionHash,
                  CoinType: "BNB",
                },
                {
                  headers: {
                    Authorization: `${jwt}`,
                    "Content-Type": "application/json", // Adjust the content type as needed
                  },
                }
              );
              // console.log("buycoin", res.data);
              toast.success("Transaction Succesful");
              if (res.data.success == true) {
                toast.success("Deposit Successful");
                navigate("/dashboard");
                setspiner(false);
              }

              setspiner(false);
            } catch (err) {
              console.log("error while calling fuction sell", err);
              if (
                e.response.data.showableMessage ==
                "Please login to access this resource"
              ) {
                dispatch(
                  UpdateAuth({ isAuth: false, userId: null, jwt_token: null })
                );
                navigate("/");
              }
              setspiner(false);
            }
          }
        } else {
          toast.error("Please Enter Amout First");
        }
      } else {
        toast.error("Invalid Wallet address");
      }
    }
    setspiner(false);
  };

  useEffect(() => {
    walletConnected();
    USDTBalance();
    GetUserDetails();
  }, []);

  return (
    <div>
      <div className="page-wrapper">
        <div className="page-content">
          <div className="row mx-0  py-3">
            <div className="col-12">
              <div className="d-flex align-items-center justify-content-between">
                <div className="fs-18 text-white fw-bold">Add Fund</div>
                <div className="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
                  <div className="breadcrumb-title pe-3">Add</div>
                  <div className="ps-3">
                    <nav aria-label="breadcrumb">
                      <ol className="breadcrumb mb-0 p-0">
                        <li className="breadcrumb-item">
                          <a href="">
                            <i className="bx bx-home-alt text-white" />
                          </a>
                        </li>
                        <li
                          className="breadcrumb-item active"
                          aria-current="page">
                          Add Fund
                        </li>
                      </ol>
                    </nav>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-3"></div>
            {spiner && <Spinner />}
            <div className="col-md-6">
              <div className="modal-dialog">
                <div className="modal-content exchange_width">
                  <div className="modal-header exchange_hd w-100">
                    <h4 className="w-100 text-center pt-2">
                      Available USDT : {USDT} USDT
                    </h4>
                  </div>
                  <div className="modal-body">
                    <div
                      className="box box-default table-wrapper ng-pristine ng-valid ng-valid-maxlength mt-0"
                      // ng-submit="withDrawalAmount()"
                    >
                      <div className="panel-body">
                        <div className="row align-items-center justify-content-between">
                          <div className="col-sm-4 col-6">
                            <label>Enter USD Amount</label>
                          </div>
                          <div className="col-sm-8 col-6">
                            <input
                              type="text"
                              className="form-control mb-20 shadow-none"
                              id="amountInput"
                              defaultValue={0}
                              value={Amount}
                              readOnly
                              style={{ width: "94%" }}
                            />
                          </div>
                        </div>
                        <div className="row justify-content-center">
                          <div className="col-12 mt-3 text-center">
                            {/* Add an onclick event to each paymentadd button */}
                            <div
                              className="btn btn-Green py-2 px-3 paymentadd"
                              onClick={() => CalculateValue(10)}>
                              $ 10
                            </div>
                            <div
                              className="btn btn-Green py-2 px-3 paymentadd"
                              onClick={() => CalculateValue(50)}>
                              $ 50
                            </div>
                            <div
                              className="btn btn-Green py-2 px-3 paymentadd"
                              onClick={() => CalculateValue(100)}>
                              $ 100
                            </div>
                            <div
                              className="btn btn-Green py-2 px-3 paymentadd"
                              onClick={() => CalculateValue(500)}>
                              $ 500
                            </div>
                            <div
                              className="btn btn-Green py-2 px-3 paymentadd"
                              onClick={() => CalculateValue(1000)}>
                              $ 1000
                            </div>
                          </div>
                          <div className="col-12 text-center py-3">
                            {/* Add an onclick event to the Reset button */}
                            <button
                              className="btn btn-Green py-2 px-5 restart"
                              onClick={() => setAmount(0)}>
                              Reset
                            </button>
                          </div>
                        </div>
                        <div className="row align-items-center justify-content-center mx-0">
                          <div className="col-sm-6 row align-items-center justify-content-center">
                            <button
                              className="btn btn-success withdraw_btn w-100 py-3 shadow-none"
                              onClick={() => BuyToken()}>
                              Make Payment
                            </button>
                          </div>
                          <div className="col-12 ">
                            <p className="text-white fs-6 fw-bold text-center py-2 mb-0 notetext">
                              Note: Deposit Amount Should Be Greater Than $10.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/*end row*/}
          <br />
          <br />
        </div>
      </div>
    </div>
  );
}
