import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isAuth: false,
  userId: "",
  jwt_token:'',
  status:'Inactive',
  dashboardData: null, 
};

export const AuthSlice = createSlice({
  name: "UserAuth",
  initialState,
  reducers: {
    UpdateAuth: (state, action) => {
      state.isAuth = action.payload.isAuth;
      state.userId = action.payload.userId;
      state.jwt_token=action.payload.jwt_token
    },
    updateStatus:(state,action)=>{
      state.status=action.payload.status
    }, 
    resetAuth: (state) => {
      state.isAuth = false;
      state.userId = null;
      state.jwt_token = null;
      localStorage.clear();
    },
    SetDashboardData: (state, action) => {
      state.dashboardData = action.payload;
    },
  },
});
export const { UpdateAuth, SetDashboardData,updateStatus, resetAuth } = AuthSlice.actions;
export default AuthSlice.reducer;
