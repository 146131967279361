import React, { useEffect, useState } from "react";
import { API } from "../API/Api";
import ShareTable from "../Components/Table/Table";
import { useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";

const columns = [
  {
    dataField: "Number",
    text: "S.No",
    sort: false,
  },
  {
    dataField: "Amount",
    text: "Amount",
    sort: false,
  },
  {
    dataField: "Token",
    text: "Point",
    sort: false,
  },
  {
    dataField: "Date",
    text: "Date",
    sort: false,
  },
  {
    dataField: "Sell_Type",
    text: "Remark",
    sort: false,
  },
];

const row = [
  {
    Number: "1",
    Amount: "100",
    Token: "12345",
    Date: "12-06-2024",
    Sell_Type: "Case",
  },
  {
    Number: "2",
    Amount: "100",
    Token: "12345",
    Date: "12-06-2024",
    Sell_Type: "Case",
  },
  {
    Number: "3",
    Amount: "100",
    Token: "12345",
    Date: "12-06-2024",
    Sell_Type: "Case",
  },
];

export default function GameWalletHistory() {
  const [searchParams, setSearchParams] = useSearchParams();
  const [dataArray, setdataArray] = useState([]);
  const user = useSelector((state) => state.Auth.userId);
  const jwt = useSelector((state) => state.Auth.jwt_token);
  const [fromDateFilter, setFromDateFilter] = useState("");
  const [toDateFilter, setToDateFilter] = useState("");
  const [currentPageSize, setCurrentPageSize] = useState(10);
  const [type, settype] = useState(searchParams.get("type"));
  const Sell_Token_Report_API = async () => {
    try {
      let responce = await API?.post(
        `/WalletConvertHistory`,
        {
          uid: user,
          LoginId: user,
          type: type,
        },
        {
          headers: {
            Authorization: `${jwt}`,
            "Content-Type": "application/json",
          },
        }
      );
      console.log("SellTokenHistory", responce);
      responce = responce?.data?.data[0];
      

      let arr = [];
      responce?.forEach((item, index) => {
        arr.push({
          Number: item?.rownumber,
          Amount: item?.NetAmount,
          Token: item?.NetPoint,
          Date: item?.edate1,
          Sell_Type: item?.Remark,
        });
      });

      setdataArray(arr);
    } catch (e) {
      console.log("Error While calling Referrer API", e);
    }
  };

  const handlePageSizeChange = (size) => {
    setCurrentPageSize(size);
  };

  const resetFilters = () => {
    setFromDateFilter("");
    setToDateFilter("");
    Sell_Token_Report_API();
  };

  useEffect(() => {
    Sell_Token_Report_API();
  }, []);
  return (
    <div>
      <div className="page-wrapper">
        <div className="page-content adjustmentsection">
          {/*breadcrumb*/}
          <div className="d-flex align-items-center justify-content-between py-3">
            <div className="fs-18 text-white fw-bold">
              {type === "1" ? (
                <>Withdrawal To Game Wallet Convert History</>
              ) : (
                <>Game To Withdrawal Wallet Convert History</>
              )}
            </div>
            <div className="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
              <div className="breadcrumb-title pe-3">Game</div>
              <div className="ps-3">
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb mb-0 p-0">
                    <li className="breadcrumb-item">
                      <a href="javascript:;">
                        <i className="bx bx-home-alt text-white" />
                      </a>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                    {type === "1" ? (
                <>Withdrawal To Game Wallet Convert History</>
              ) : (
                <>Game To Withdrawal Wallet Convert History</>
              )}
                    </li>
                  </ol>
                </nav>
              </div>
            </div>
          </div>
          <div className="row mx-0">
            <div className="col-12">
              <div className="card bg-transparent mb-0 py-2">
                <div className="card-body">
                  <div className="row mx-0">
                    <ShareTable
                      columns={columns}
                      Data={dataArray}
                      defaultPageSize={currentPageSize}
                      pageSizeOptions={["10", "50", "100"]}
                      onPageSizeChange={handlePageSizeChange}
                      style={{ width: "100%" }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/*end row*/}
        </div>
      </div>
    </div>
  );
}
