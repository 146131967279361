import React from "react";

export default function TreeHoverNode({data}) {
  return (
    <div>
      <div className="dropdown-content">
        <table className="tables" cellPadding="0px" cellSpacing="0px">
          <tbody>
           
            <tr className="fgtybmd">
              <td className="table_heading">Sponsor :</td>
              <td className="grtydfbc" colSpan={3}>
              {data?.Sponsor}
              </td>
            </tr>
            <tr>
              <td className="table_heading">Registration&nbsp;Date :</td>
              <td className="grtydfbc">{data?.registration_date}</td>
              {/* <td className="table_heading">Topup &nbsp;Date :</td>
              <td className="grtydfbc">31 Dec 2020</td> */}
            </tr>
            <tr>
              <td className="table_heading">Status :</td>
              <td className="grtydfbc">{data?.status}</td>
              <td className="table_heading">Package :</td>
              <td className="grtydfbc">{data.package}</td>
            </tr>
            <tr>
              <td className="table_heading">Total Left :</td>
              <td className="grtydfbc">{data?.total_left}</td>
              <td className="table_heading">Total Right :</td>
              <td className="grtydfbc">{data?.total_right}</td>
            </tr>
            <tr>
              <td className="table_heading">Total Left Active :</td>
              <td className="grtydfbc">{data?.total_left_active}</td>
              <td className="table_heading">Total Right Active :</td>
              <td className="grtydfbc">{data?.total_right_active}</td>
            </tr>
            <tr>
              <td className="table_heading">Left Business :</td>
              <td className="grtydfbc">{data?.left_business}</td>
              <td className="table_heading">Right Business :</td>
              <td className="grtydfbc">{data?.right_business}</td>
            </tr>
            <tr>
              <td className="table_heading">Today Left Business :</td>
              <td className="grtydfbc">{data?.today_left_business}</td>
              <td className="table_heading">Today Right Business :</td>
              <td className="grtydfbc">{data?.today_right_business}</td>
            </tr>
            <tr>
              <td className="table_heading">Package amount :</td>
              <td className="grtydfbc">{data?.package_amount}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
}
