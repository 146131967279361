import React, { useEffect, useState } from "react";
import user3red from "../../Assets/TreeImage/redicon.png";
import Default from "../../Assets/TreeImage/blackicon.png";
import Active from "../../Assets/TreeImage/greenicon.png";
import tree from "../../Assets/treeimg.png";
import tree1 from "../../Assets/treeimg1.png";
import tree2 from "../../Assets/treeimg2.png";
import { API } from "../../API/Api";

import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";

import { useSelector } from "react-redux";

import toast from "react-hot-toast";
import DOMPurify from "dompurify";
import MatrixHoverNode from "./MatrixHoverNode";

let bol = true;
export default function Matrixnewtree() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const user = useSelector((state) => state.Auth.userId);
  const jwt = useSelector((state) => state.Auth.jwt_token);
  // console.log("User",user);

  const [Idnumer, setIdnumer] = useState(user);
  const [arrValue, setArrValue] = useState([]);
  const [getValue, setgetValue] = useState(Idnumer);
  const [loader, setloader] = useState(false);
  const [searchvalue, setsearchvalue] = useState(0);

  const [userdata, setuserdata] = useState([
    {
      name: "",
      id: "",
      registration_date: "",
      status: "",
      total_left: "",
      total_left_active: "",
      left_business: "",
      package_amount: "",
      Activation_date: "",
      package: "",
      total_right: "",
      total_right_active: "0",
      right_business: "",
      Sponsor: "",
    },
    {
      name: "",
      id: "",
      registration_date: "",
      status: "",
      total_left: "",
      total_left_active: "",
      left_business: "",
      package_amount: "",
      Activation_date: "",
      package: "",
      total_right: "",
      total_right_active: "",
      right_business: "",
      Sponsor: "",
    },
    {
      name: "",
      id: "",
      registration_date: "",
      status: "",
      total_left: "",
      total_left_active: "",
      left_business: "",
      package_amount: "",
      Activation_date: "",
      package: "",
      total_right: "",
      total_right_active: "",
      right_business: "",
      Sponsor: "",
    },
    {
      name: "",
      id: "",
      registration_date: "",
      status: "",
      total_left: "",
      total_left_active: "",
      left_business: "",
      package_amount: "",
      Activation_date: "",
      package: "",
      total_right: "",
      total_right_active: "",
      right_business: "",
      Sponsor: "",
    },
    {
      name: "",
      id: "",
      registration_date: "",
      status: "",
      total_left: "",
      total_left_active: "",
      left_business: "",
      package_amount: "",
      Activation_date: "",
      package: "",
      total_right: "",
      total_right_active: "",
      right_business: "",
      Sponsor: "",
    },
    {
      name: "",
      id: "",
      registration_date: "",
      status: "",
      total_left: "",
      total_left_active: "",
      left_business: "",
      package_amount: "",
      Activation_date: "",
      package: "",
      total_right: "",
      total_right_active: "",
      right_business: "",
      Sponsor: "",
    },
    {
      name: "",
      id: "",
      registration_date: "",
      status: "",
      total_left: "",
      total_left_active: "",
      left_business: "",
      package_amount: "",
      Activation_date: "",
      package: "",
      total_right: "",
      total_right_active: "",
      right_business: "",
      Sponsor: "",
    },
    {
      name: "",
      id: "",
      registration_date: "",
      status: "",
      total_left: "",
      total_left_active: "",
      left_business: "",
      package_amount: "",
      Activation_date: "",
      package: "",
      total_right: "",
      total_right_active: "",
      right_business: "",
      Sponsor: "",
    },
    {
      name: "",
      id: "",
      registration_date: "",
      status: "",
      total_left: "",
      total_left_active: "",
      left_business: "",
      package_amount: "",
      Activation_date: "",
      package: "",
      total_right: "",
      total_right_active: "",
      right_business: "",
      Sponsor: "",
    },
    {
      name: "",
      id: "",
      registration_date: "",
      status: "",
      total_left: "",
      total_left_active: "",
      left_business: "",
      package_amount: "",
      Activation_date: "",
      package: "",
      total_right: "",
      total_right_active: "",
      right_business: "",
      Sponsor: "",
    },
    {
      name: "",
      id: "",
      registration_date: "",
      status: "",
      total_left: "",
      total_left_active: "",
      left_business: "",
      package_amount: "",
      Activation_date: "",
      package: "",
      total_right: "",
      total_right_active: "",
      right_business: "",
      Sponsor: "",
    },
    {
      name: "",
      id: "",
      registration_date: "",
      status: "",
      total_left: "",
      total_left_active: "",
      left_business: "",
      package_amount: "",
      Activation_date: "",
      package: "",
      total_right: "",
      total_right_active: "",
      right_business: "",
      Sponsor: "",
    },
    {
      name: "",
      id: "",
      registration_date: "",
      status: "",
      total_left: "",
      total_left_active: "",
      left_business: "",
      package_amount: "",
      Activation_date: "",
      package: "",
      total_right: "",
      total_right_active: "",
      right_business: "",
      Sponsor: "",
    },
    {
      name: "",
      id: "",
      registration_date: "",
      status: "",
      total_left: "",
      total_left_active: "",
      left_business: "",
      package_amount: "",
      Activation_date: "",
      package: "",
      total_right: "",
      total_right_active: "",
      right_business: "",
      Sponsor: "",
    },
    {
      name: "",
      id: "",
      registration_date: "",
      status: "",
      total_left: "",
      total_left_active: "",
      left_business: "",
      package_amount: "",
      Activation_date: "",
      package: "",
      total_right: "",
      total_right_active: "",
      right_business: "",
      Sponsor: "",
    },
  ]);
  const referral_API = async () => {
    try {
      let responce = await API?.post(
        "/matrixTree",
        {
          searchId: Idnumer,
          loginId: user,
          pool: 1,
        },
        {
          headers: {
            Authorization: `${jwt}`,
            "Content-Type": "application/json", // Adjust the content type as needed
          },
        }
      );
      responce = responce?.data?.data[0];
      console.log("matrixTree", responce);
      if (responce == undefined) {
        toast.error("User ID Not Found");
        setloader(false);
      } else {
        setloader(true);

        let arr = [];
        responce.forEach((item, index) => {
          arr.push({
            name: item.fname,
            id: item.uid,
            package_amount: item.packageamount,
            Activation_date: item.activationdate,
            Sponsor: item.sid,
            downline: item.mdownline,
            gross: item.Gross,
            latest: item.Latest,
          });
        });
        // console.log("responce", arr);

        // setloader(false);

        setuserdata(arr);
        if (bol) {
          setArrValue([...arrValue, arr[0].id]);
          bol = false;
        }
      }
    } catch (e) {
      // setloader(false);
      console.log("Error While calling Referrer API", e);
    }
  };
  function addValue(value) {
    setArrValue([...arrValue, value]);
    // arrValue.push(value)
    // arrValue.push(value)
  }

  var a;
  function popoutvalue() {
    if (arrValue.length == 1) {
      // setloader(true)
      arrValue.pop();
      arrValue.unshift(user);
      bol = true;
    } else {
      a = arrValue.splice(arrValue.length - 2, 1);
      setIdnumer(a[0]);
    }
  }

  const getIdArray = (arr) => {
    // console.log("arry",arr);
    setArrValue([...arrValue, arr]);
  };

  // console.log("arrValue",arrValue);
  const prev_id = () => {
    if (arrValue.length === 1) {
      // arrValue.pop();
      arrValue.unshift(user);
      bol = true;
    } else {
      let value = arrValue.splice(arrValue.length - 2, 1);
      // console.log("value", value[0]);
      setgetValue(value[0]);
      setIdnumer(value[0]);
    }
  };

  useEffect(() => {
    referral_API();
  }, [Idnumer, arrValue, getValue]);

  return (
    <div>
      <div className="page-wrapper">
        <div className="page-content pb-5 treeviewpage">
          <div className="row">
            <div class="col-lg-6">
              <div class="card">
                <div class="card-body">
                  <div class="form-wrapper">
                    <input
                      class="form-control srch_frm bg-white border-0 shadow-none text-black"
                      id="ddluserid"
                      name="userId"
                      type="text"
                      Value={Idnumer}
                      onChange={(e) =>
                        setgetValue(
                          DOMPurify.sanitize(
                            e.target.value
                              .replace(/[^0-9]/gi, "")
                              .substring(0, 8)
                          )
                        )
                      }
                    />
                    <input
                      type="submit"
                      value="SUBMIT"
                      id="Search"
                      class="btn btn-sm btn-theme2 submit_btn text-white border-0 shadow-none px-3"
                      onClick={() => (
                        setIdnumer(getValue), getIdArray(getValue)
                      )}
                    />
                    <button
                      id="#home"
                      type="button"
                      class="btn btn-sm btn-danger border-0 shadow-none px-3"
                      onClick={() => setIdnumer(user)}
                    >
                      Home
                    </button>
                    <button
                      onClick={prev_id}
                      type="button"
                      class="btn btn-sm btn-danger shadow-none border-0 px-3"
                    >
                      BACK
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="card">
                <div className="card-body">
                  <div className="tree_icon">
                    <div className="tree_name">
                      <img src={Active} width="40px" height="40px" />
                      Active
                    </div>
                    <div className="tree_name">
                      <img src={Default} width="40px" height="40px" />
                      In-Active
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-12">
              <div className="card">
                <div
                  id="tree"
                  className="treeview"
                  style={{ margin: "30px auto" }}
                >
                  <div className="treemember">
                    <div className="dropdown">
                      <button className="dropbtn">
                        <img
                          src={userdata[0].id != "" ? Active : Default}
                          className="abc"
                          onclick="Image_Click(364734)"
                        />
                      </button>
                      <div className="span text-white">
                        {" "}
                        User
                        <br />
                        {userdata[0].id}
                      </div>
                      <MatrixHoverNode data={userdata[0]} />
                    </div>
                  </div>
                  <div className="connecter1">
                    <img src={tree} style={{ width: 655, height: 33 }} />
                  </div>
                  <div className="tree_row">
                    <div className="row_2_child">
                      <div className="dropdown">
                        <button className="dropbtn">
                          <img
                            src={userdata[1].id != "" ? Active : Default}
                            className="abc"
                            onClick={() => (
                              setIdnumer(userdata[1].id),
                              addValue(userdata[1].id)
                            )}
                          />
                        </button>
                        <div className="span text-white">

                          {userdata[1].id}
                        </div>
                        <MatrixHoverNode data={userdata[1]} />
                      </div>
                    </div>

                    <div className="row_2_child">
                      <div className="dropdown">
                        <button className="dropbtn">
                          <img
                            src={userdata[2].id != "" ? Active : Default}
                            className="abc"
                            onClick={() => (
                              setIdnumer(userdata[2].id),
                              addValue(userdata[2].id)
                            )}
                          />
                        </button>
                        <div class="span text-white">
                          {userdata[2].id}
                          <br />
                        </div>
                        <MatrixHoverNode data={userdata[2]} />

                      </div>
                    </div>

                    <div className="row_2_child">
                      <div className="dropdown">
                        <button className="dropbtn">
                          <img
                            src={userdata[3].id != "" ? Active : Default}
                            className="abc"
                            onClick={() => (
                              setIdnumer(userdata[3].id),
                              addValue(userdata[3].id)
                            )}
                          />
                        </button>
                        <div class="span text-white">
                          {userdata[3].id}
                          <br />
                        </div>
                        <MatrixHoverNode data={userdata[3]} />
                      </div>
                    </div>
                  </div>
                  <div className="tree_row1" style={{ height: 34,marginTop:"2rem" }}>
                    <div className="row_5_child" style={{ height: 34 }}>
                      <img src={tree1} style={{ width: 253, height: 32 }} />
                    </div>
                    <div className="row_5_child" style={{ height: 34 }}>
                      <img src={tree1} style={{ width: 253, height: 32 }} />
                    </div>
                    <div className="row_5_child" style={{ height: 34 }}>
                      <img src={tree1} style={{ width: 253, height: 32 }} />
                    </div>
                  </div>
                  <div className="tree_row2">
                    <div className="row_3_child">
                      <div className="dropdown">
                        <button className="dropbtn">
                          <img
                            src={userdata[4].id != "" ? Active : Default}
                            className="abc"
                            onClick={() => (
                              setIdnumer(userdata[4].id),
                              addValue(userdata[4].id)
                            )}
                          />
                        </button>
                        <div class="span text-white">
                          {userdata[4].id}
                          <br />
                        </div>
                        <MatrixHoverNode data={userdata[4]} />
                      </div>
                    </div>
                    <div className="row_3_child">
                      <div className="dropdown">
                        <button className="dropbtn">
                          <img
                            src={userdata[5].id != "" ? Active : Default}
                            className="abc"
                            onClick={() => (
                              setIdnumer(userdata[5].id),
                              addValue(userdata[5].id)
                            )}
                          />
                        </button>
                        <div class="span text-white">
                          {userdata[5].id}
                          <br />
                        </div>
                        <MatrixHoverNode data={userdata[5]} />
                      </div>
                    </div>
                    <div className="row_3_child">
                      <div className="dropdown">
                        <button className="dropbtn">
                          <img
                            src={userdata[6].id != "" ? Active : Default}
                            className="abc"
                            onClick={() => (
                              setIdnumer(userdata[6].id),
                              addValue(userdata[6].id)
                            )}
                          />
                        </button>
                        <div class="span text-white">
                          {userdata[6].id}
                          <br />
                        </div>

                        <MatrixHoverNode data={userdata[6]} />
                      </div>
                    </div>
                    <div className="row_3_child">
                      <div className="dropdown">
                        <button className="dropbtn">
                          <img
                            src={userdata[7].id != "" ? Active : Default}
                            className="abc"
                            onClick={() => (
                              setIdnumer(userdata[7].id),
                              addValue(userdata[7].id)
                            )}
                          />
                        </button>
                        <div class="span text-white">
                          {userdata[7].id}
                          <br />
                        </div>
                        <MatrixHoverNode data={userdata[7]} />
                      </div>
                    </div>
                    <div className="row_3_child">
                      <div className="dropdown">
                        <button className="dropbtn">
                          <img
                            src={userdata[8].id != "" ? Active : Default}
                            className="abc"
                            onClick={() => (
                              setIdnumer(userdata[8].id),
                              addValue(userdata[8].id)
                            )}
                          />
                        </button>
                        <div class="span text-white">
                          {userdata[8].id}
                          <br />
                        </div>
                        <MatrixHoverNode data={userdata[8]} />
                      </div>
                    </div>
                    <div className="row_3_child">
                      <div className="dropdown">
                        <button className="dropbtn">
                          <img
                            src={userdata[9].id != "" ? Active : Default}
                            className="abc"
                            onClick={() => (
                              setIdnumer(userdata[9].id),
                              addValue(userdata[9].id)
                            )}
                          />
                        </button>
                        <div class="span text-white">
                          {userdata[9].id}
                          <br />
                        </div>
                        <MatrixHoverNode data={userdata[9]} />
                      </div>
                    </div>
                    <div className="row_3_child">
                      <div className="dropdown">
                        <button className="dropbtn">
                          <img
                            src={userdata[10].id != "" ? Active : Default}
                            className="abc"
                            onClick={() => (
                              setIdnumer(userdata[10].id),
                              addValue(userdata[10].id)
                            )}
                          />
                        </button>

                        <div class="span text-white">
                          {userdata[10].id}
                          <br />
                        </div>
                        <MatrixHoverNode data={userdata[10]} />
                      </div>
                    </div>
                    <div className="row_3_child">
                      <div className="dropdown">
                        <button className="dropbtn">
                          <img
                            src={userdata[11].id != "" ? Active : Default}
                            className="abc"
                            onClick={() => (
                              setIdnumer(userdata[11].id),
                              addValue(userdata[11].id)
                            )}
                          />
                        </button>
                        <div class="span text-white">
                          {userdata[11].id}
                          <br />
                        </div>

                        <MatrixHoverNode data={userdata[11]} />
                      </div>
                    </div>
                    <div className="row_3_child">
                      <div className="dropdown">
                        <button className="dropbtn">
                          <img
                            src={userdata[12].id != "" ? Active : Default}
                            className="abc"
                            onClick={() => (
                              setIdnumer(userdata[12].id),
                              addValue(userdata[12].id)
                            )}
                          />
                        </button>
                        <div class="span text-white">
                          {userdata[12].id}
                          <br />
                        </div>
                        <MatrixHoverNode data={userdata[12]} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
