import React, { useState } from "react";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import TableOldButton from "./TableOldButton"; 


const ShareTable = ({ columns, Data, defaultPageSize, pageSizeOptions, onPageSizeChange }) => {
  // const [currentPage, setCurrentPage] = useState(1);
  // const [pageSize, setPageSize] = useState(10);

  
  // const handleNextPage = () => {
  //   if (currentPage < Data?.length) setCurrentPage(currentPage + 1);
  // };

  
  // const handlePrevPage = () => {
  //   if (currentPage > 1) setCurrentPage(currentPage - 1);
  // };

  
  // const handleSizePerPage = (newSizePerPage) => {
  //   setPageSize(newSizePerPage);
  // };

  
  // const handlePageChange = (page) => {
  //   setCurrentPage(page);
  // };

  
  // const handleSizeChange = (current, size) => {
  //   setPageSize(size);
  // };

  return (
    <>
      <div className="TableMain" style={{ width: "100%" }}>
        <PaginationProvider
          pagination={paginationFactory({
            custom: true,
            totalSize: Data.length,
            sizePerPage: defaultPageSize,
          })}
        >
          {({ paginationProps, paginationTableProps }) => (
            <div>
              {/* Bootstrap Table */}
              <BootstrapTable
                keyField="id"
                bordered={false}
                bootstrap4
                data={Data}
                columns={columns}
                wrapperClasses="table-responsive"
                {...paginationTableProps}
              />

             
              {/* <div className="btn-group" role="group">
                <div className="pagination-container">
                  <div className="pagination-box">
                    <ul>
                      <li
                        className="page-last d-flex align-items-center justify-content-center "
                        onClick={handlePrevPage}
                      >
                        <a className="d-flex align-items-center justify-content-center">
                          <span className="d-flex align-items-center justify-content-center leftimage">
                            <img
                              src="./assets/images/angle-left.svg"
                              alt=""
                            />
                          </span>
                        </a>
                      </li>
                      <li className="page-number on" data-page={currentPage}>
                        <a>{currentPage}</a>
                      </li>
                      <li
                        className="page-next d-flex align-items-center justify-content-center "
                        onClick={handleNextPage}
                      >
                        <a className="d-flex align-items-center justify-content-center">
                          <span className="d-flex align-items-center justify-content-center leftimage">
                            <img
                              src="./assets/images/angle-right.svg"
                              alt=""
                            />
                          </span>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div> */}

              {/* Ant Design Pagination */}
              <TableOldButton
                setcurrentPage={paginationProps.onPageChange}
                currentPage={paginationProps.page}
                totalData={Data.length}
                listPerpage={paginationProps.sizePerPage}
                handleRowsPerPageChange={onPageSizeChange}
                pageSizeOptions={pageSizeOptions}
              />
            </div>
          )}
        </PaginationProvider>
      </div>
    </>
  );
};

export default ShareTable;
