import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

export default function Header() {
  const [isMobile, setIsMobile] = useState(false);
  const [checkState, setcheckState] = useState(false);

  const { status } = useSelector((state) => state.Auth);
  useEffect(() => {
    // const handleResize = () => {
    //   const isMobileQuery = window.matchMedia("(max-width: 1024px)").matches;
    //   setIsMobile(isMobileQuery);
    // };
    // Initial check on component mount
    // handleResize();
    // // Add listener for changes in the media query
    // window.addEventListener("resize", handleResize);
    // // Clean up the event listener on component unmount
    // return () => {
    //   window.removeEventListener("resize", handleResize);
    // };
  }, [checkState]); // Empty dependency array to run the effect only once on mount

  return (
    <div>
      <div className="nav_top">
        <div className="nav2 container-fluid px_0">
          <div className="nav-header">
            <div className="nav-title">
              <Link to="/DashBoard">
                <a>
                  <img src="assets/images/logo.png" />
                </a>
              </Link>
            </div>
            
            <div className="nav-btn" onClick={() => setIsMobile(!isMobile)}>
              <label htmlFor="nav-check">
                <span />
                <span />
                <span />
              </label>
            </div>
            <input type="checkbox" name="" id="nav-check" />
            <div className="nav-links inFull">
              <ul>
                <li>
                  <Link
                    to="/DashBoard"
                    style={{ textDecoration: "none", padding: "0" }}
                    onClick={() => setcheckState(true)}
                  >
                    <a>
                      <i className="bx bx-home-circle" />
                      Dashboard
                    </a>
                  </Link>
                </li>
              
                <li className="dropdown open3">
                  <Link
                    to="/Adjustment"
                    style={{ textDecoration: "none", padding: "0" }}
                    onClick={() => setcheckState(true)}
                  >
                    <a className="dropbtn">
                      <img src="assets/images/history.png" />
                      Adjustment
                    </a>
                  </Link>
                </li>
                <li className="dropdown open4">
                  <a className="dropbtn">
                    <Link
                      to="/Sell_Rate_Chart"
                      style={{ textDecoration: "none", padding: "0" }}
                      onClick={() => setcheckState(true)}
                    >
                      <i className="bx bx-group" /> Buy/Sell Rate Chart
                    </Link>
                  </a>
                </li>
                <li className="dropdown open2">
                  <a href="#" className="dropbtn">
                    <i className="bx bx-line-chart" />
                    Add Fund
                  </a>
                  <ul className="dropdown-content closenav2">
                    <li>
                      <Link
                        to="/Add_Fund"
                        style={{ textDecoration: "none", padding: "0" }}
                        onClick={() => setcheckState(true)}
                      >
                        <a>
                          <i className="bx bx-right-arrow-alt" />
                          Add Fund
                        </a>
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/Fund_Report"
                        style={{ textDecoration: "none", padding: "0" }}
                        onClick={() => setcheckState(true)}
                      >
                        <a>
                          <i className="bx bx-right-arrow-alt" />
                          Fund Report
                        </a>
                      </Link>
                    </li>
                  </ul>
                </li>
                <li className="dropdown open8">
                  <a href="#" className="dropbtn">
                    <i className="bx bx-message-square-edit" />
                    Activaion/Upgrade
                  </a>
                  <ul className="dropdown-content closenav8">
                    <li>
                      <Link
                        to="/Activate_Upgrade"
                        style={{ textDecoration: "none", padding: "0" }}
                        onClick={() => setcheckState(true)}
                      >
                        <a>
                          <i className="bx bx-right-arrow-alt" />
                          Activaion/Upgrade
                        </a>
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/Activate_Upgrade_History"
                        style={{ textDecoration: "none", padding: "0" }}
                        onClick={() => setcheckState(true)}
                      >
                        <a>
                          <i className="bx bx-right-arrow-alt" />
                          Activaion History
                        </a>
                      </Link>
                    </li>
                  </ul>
                </li>
                <li className="dropdown open7">
                  <a href="#" className="dropbtn">
                    {" "}
                    <i className="bx bx-message-square-edit" />
                    Buy
                  </a>
                  <ul className="dropdown-content closenav7">
                    <li>
                      <Link
                        to="/Buy_Token"
                        style={{ textDecoration: "none", padding: "0" }}
                        onClick={() => setcheckState(true)}
                      >
                        <a>
                          <i className="bx bx-right-arrow-alt" />
                          Buy Token
                        </a>
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/Buy_Token_Report"
                        style={{ textDecoration: "none", padding: "0" }}
                        onClick={() => setcheckState(true)}
                      >
                        <a>
                          <i className="bx bx-right-arrow-alt" />
                          Buy Token Report
                        </a>
                      </Link>
                    </li>
                  </ul>
                </li>
                <li className="dropdown open9">
                  <a href="#" className="dropbtn">
                    <i className="bx bx-atom" />
                    Sell
                  </a>
                  <ul className="dropdown-content closenav9">
                    <li>
                      <Link
                        to="/Sell_Token"
                        style={{ textDecoration: "none", padding: "0" }}
                        onClick={() => setcheckState(true)}
                      >
                        <a>
                          <i className="bx bx-right-arrow-alt" />
                          Sell Token
                        </a>
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/Sell_Token_Report"
                        style={{ textDecoration: "none", padding: "0" }}
                        onClick={() => setcheckState(true)}
                      >
                        <a>
                          <i className="bx bx-right-arrow-alt" />
                          Sell Token Report
                        </a>
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/Auto_Sell_Token_Report"
                        style={{ textDecoration: "none", padding: "0" }}
                        onClick={() => setcheckState(true)}
                      >
                        <a>
                          <i className="bx bx-right-arrow-alt" />
                          Auto Sell Token Report
                        </a>
                      </Link>
                    </li>
                  </ul>
                </li>
                <li className="dropdown open5">
                  <a href="#" className="dropbtn">
                    {" "}
                    <i className="bx bx-message-square-edit" />
                    Genealogy
                  </a>
                  <ul className="dropdown-content closenav5">
                    <li>
                      <Link
                        to="/Tree_View"
                        style={{ textDecoration: "none", padding: "0" }}
                        onClick={() => setcheckState(true)}
                      >
                        <a>
                          <i className="bx bx-right-arrow-alt" />
                          Tree View
                        </a>
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/Direct_User_List"
                        style={{ textDecoration: "none", padding: "0" }}
                        onClick={() => setcheckState(true)}
                      >
                        <a>
                          <i className="bx bx-right-arrow-alt" />
                          Direct User List
                        </a>
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/Level_Details"
                        style={{ textDecoration: "none", padding: "0" }}
                        onClick={() => setcheckState(true)}
                      >
                        <a>
                          <i className="bx bx-right-arrow-alt" />
                          Level Details
                        </a>
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/Downline"
                        style={{ textDecoration: "none", padding: "0" }}
                        onClick={() => setcheckState(true)}
                      >
                        <a>
                          <i className="bx bx-right-arrow-alt" />
                          Downline
                        </a>
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/Matrix_Tree"
                        style={{ textDecoration: "none", padding: "0" }}
                        onClick={() => setcheckState(true)}
                      >
                        <a href="Matrix_Tree.html">
                          <i className="bx bx-right-arrow-alt" />
                          Matrix Tree
                        </a>
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/Matrix_Downline"
                        style={{ textDecoration: "none", padding: "0" }}
                        onClick={() => setcheckState(true)}
                      >
                        <a href="Matrix_Downline.html">
                          <i className="bx bx-right-arrow-alt" />
                          Matrix Downline
                        </a>
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/TodayDownlineReport"
                        style={{ textDecoration: "none", padding: "0" }}
                        onClick={() => setcheckState(true)}
                      >
                        <a href="Matrix_Downline.html">
                          <i className="bx bx-right-arrow-alt" />
                          Today Downline Report
                        </a>
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/TotalDownlineReport"
                        style={{ textDecoration: "none", padding: "0" }}
                        onClick={() => setcheckState(true)}
                      >
                        <a href="Matrix_Downline.html">
                          <i className="bx bx-right-arrow-alt" />
                          Total Downline Report
                        </a>
                      </Link>
                    </li>
                  </ul>
                </li>
                <li className="dropdown open6">
                  <a href="#" className="dropbtn">
                    {" "}
                    <i className="bx bx-message-square-edit" />
                    Reports
                  </a>
                  <ul className="dropdown-content closenav6">
                    <li>
                      <Link
                        to="/Direct_Earning_Income"
                        style={{ textDecoration: "none", padding: "0" }}
                        onClick={() => setcheckState(true)}
                      >
                        <a>
                          <i className="bx bx-right-arrow-alt" />
                          Direct Earning
                        </a>
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/Binary_Earning_Report"
                        style={{ textDecoration: "none", padding: "0" }}
                        onClick={() => setcheckState(true)}
                      >
                        <a href="Binary_Earning_Report.html">
                          <i className="bx bx-right-arrow-alt" />
                          Binary Earning
                        </a>
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/Matrix_Earning"
                        style={{ textDecoration: "none", padding: "0" }}
                        onClick={() => setcheckState(true)}
                      >
                        <a>
                          <i className="bx bx-right-arrow-alt" />
                          Matrix Earning
                        </a>
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/Matrix_CTO_Earning"
                        style={{ textDecoration: "none", padding: "0" }}
                        onClick={() => setcheckState(true)}
                      >
                        <a>
                          <i className="bx bx-right-arrow-alt" />
                          Matrix CTO Earning
                        </a>
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/Withdrawal_Direct_Earning"
                        style={{ textDecoration: "none", padding: "0" }}
                        onClick={() => setcheckState(true)}
                      >
                        <a>
                          <i className="bx bx-right-arrow-alt" />
                          Withdrawal Direct Earning
                        </a>
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/DirectRewardBusiness"
                        style={{ textDecoration: "none", padding: "0" }}
                        onClick={() => setcheckState(true)}
                      >
                        <a>
                          <i className="bx bx-right-arrow-alt" />
                          Direct Reward Earning
                        </a>
                      </Link>
                    </li>
                  </ul>
                </li>
                <li className="dropdown open9">
                  <a href="#" className="dropbtn">
                    <i className="bx bx-atom" />
                    Game
                  </a>
                  <ul className="dropdown-content closenav9">
                    <li>
                      <Link
                        to="/ColorGame"
                        style={{ textDecoration: "none", padding: "0" }}
                        onClick={() => setcheckState(true)}
                      >
                        <a>
                          <i className="bx bx-right-arrow-alt" />
                          Color Game
                        </a>
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/WithrawalToGameWallet"
                        style={{ textDecoration: "none", padding: "0" }}
                        onClick={() => setcheckState(true)}
                      >
                        <a>
                          <i className="bx bx-right-arrow-alt" />
                          Withdrawal to Game Wallet
                        </a>
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/GameWalletHistory?type=1"
                        style={{ textDecoration: "none", padding: "0" }}
                        onClick={() => setcheckState(true)}
                      >
                        <a>
                          <i className="bx bx-right-arrow-alt" />
                          Withdrawal to Game Wallet Convert History
                        </a>
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/GameToWithrawalWallet"
                        style={{ textDecoration: "none", padding: "0" }}
                        onClick={() => setcheckState(true)}
                      >
                        <a>
                          <i className="bx bx-right-arrow-alt" />
                          Game to Withdrawal Wallet
                        </a>
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/GameWalletHistory?type=2"
                        style={{ textDecoration: "none", padding: "0" }}
                        onClick={() => setcheckState(true)}
                      >
                        <a>
                          <i className="bx bx-right-arrow-alt" />
                           Game T Withdrawal Wallet Convert History
                        </a>
                      </Link>
                    </li>
                  </ul>
                </li>
                <li className="dropdown open10">
                  <a href="#" className="dropbtn">
                    {" "}
                    <i className="bx bx-message-square-edit" />
                    Withdrawal
                  </a>
                  <ul className="dropdown-content closenav10">
                    <li>
                      <Link
                        to="/Withdrawal"
                        style={{ textDecoration: "none", padding: "0" }}
                        onClick={() => setcheckState(true)}
                      >
                        <a>
                          <i className="bx bx-right-arrow-alt" />
                          Withdrawal
                        </a>
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/Withdrawal_History"
                        style={{ textDecoration: "none", padding: "0" }}
                        onClick={() => setcheckState(true)}
                      >
                        <a href="Withdrawal_History.html">
                          <i className="bx bx-right-arrow-alt" />
                          Withdrawal History
                        </a>
                      </Link>
                    </li>
                  </ul>
                </li>
                <li>
                  <Link
                    to="/"
                    style={{ textDecoration: "none", padding: "0" }}
                    onClick={() => setcheckState(true)}
                  >
                    <a href="Login.html">
                      <i className="bx bx-lock" />
                      Sign Out
                    </a>
                  </Link>
                </li>
              </ul>
            </div>
            {isMobile && (
              <div className="nav-links">
                <ul>
                  <li>
                    <Link
                      to="/DashBoard"
                      style={{ textDecoration: "none", padding: "0" }}
                      onClick={() => setIsMobile(false)}
                    >
                      <a>
                        <i className="bx bx-home-circle" />
                        Dashboard
                      </a>
                    </Link>
                  </li>
                  <li className="dropdown open3">
                    <Link
                      to="/Adjustment"
                      style={{ textDecoration: "none", padding: "0" }}
                      onClick={() => setIsMobile(false)}
                    >
                      <a className="dropbtn">
                        <img src="assets/images/history.png" />
                        Adjustment
                      </a>
                    </Link>
                  </li>
                  <li className="dropdown open4">
                    <a className="dropbtn">
                      <Link
                        to="/Sell_Rate_Chart"
                        style={{ textDecoration: "none", padding: "0" }}
                        onClick={() => setIsMobile(false)}
                      >
                        <i className="bx bx-group" /> Buy/Sell Rate Chart
                      </Link>
                    </a>
                  </li>
                  <li className="dropdown open2">
                    <a href="#" className="dropbtn">
                      <i className="bx bx-line-chart" />
                      Add Fund
                    </a>
                    <ul className="dropdown-content closenav2">
                      <li>
                        <Link
                          to="/Add_Fund"
                          style={{ textDecoration: "none", padding: "0" }}
                          onClick={() => setIsMobile(false)}
                        >
                          <a>
                            <i className="bx bx-right-arrow-alt" />
                            Add Fund
                          </a>
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/Fund_Report"
                          style={{ textDecoration: "none", padding: "0" }}
                          onClick={() => setIsMobile(false)}
                        >
                          <a>
                            <i className="bx bx-right-arrow-alt" />
                            Fund Report
                          </a>
                        </Link>
                      </li>
                    </ul>
                  </li>
                  <li className="dropdown open8">
                    <a href="#" className="dropbtn">
                      <i className="bx bx-message-square-edit" />
                      Activaion/Upgrade
                    </a>
                    <ul className="dropdown-content closenav8">
                      <li>
                        <Link
                          to="/Activate_Upgrade"
                          style={{ textDecoration: "none", padding: "0" }}
                          onClick={() => setIsMobile(false)}
                        >
                          <a>
                            <i className="bx bx-right-arrow-alt" />
                            Activaion/Upgrade
                          </a>
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/Activate_Upgrade_History"
                          style={{ textDecoration: "none", padding: "0" }}
                          onClick={() => setIsMobile(false)}
                        >
                          <a>
                            <i className="bx bx-right-arrow-alt" />
                            Activaion History
                          </a>
                        </Link>
                      </li>
                    </ul>
                  </li>
                  <li className="dropdown open7">
                    <a href="#" className="dropbtn">
                      {" "}
                      <i className="bx bx-message-square-edit" />
                      Buy
                    </a>
                    <ul className="dropdown-content closenav7">
                      <li>
                        <Link
                          to="/Buy_Token"
                          style={{ textDecoration: "none", padding: "0" }}
                          onClick={() => setIsMobile(false)}
                        >
                          <a>
                            <i className="bx bx-right-arrow-alt" />
                            Buy Token
                          </a>
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/Buy_Token_Report"
                          style={{ textDecoration: "none", padding: "0" }}
                          onClick={() => setIsMobile(false)}
                        >
                          <a>
                            <i className="bx bx-right-arrow-alt" />
                            Buy Token Report
                          </a>
                        </Link>
                      </li>
                    </ul>
                  </li>
                  <li className="dropdown open9">
                    <a href="#" className="dropbtn">
                      <i className="bx bx-atom" />
                      Sell
                    </a>
                    <ul className="dropdown-content closenav9">
                      <li>
                        <Link
                          to="/Sell_Token"
                          style={{ textDecoration: "none", padding: "0" }}
                          onClick={() => setIsMobile(false)}
                        >
                          <a>
                            <i className="bx bx-right-arrow-alt" />
                            Sell Token
                          </a>
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/Sell_Token_Report"
                          style={{ textDecoration: "none", padding: "0" }}
                          onClick={() => setIsMobile(false)}
                        >
                          <a>
                            <i className="bx bx-right-arrow-alt" />
                            Sell Token Report
                          </a>
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/Auto_Sell_Token_Report"
                          style={{ textDecoration: "none", padding: "0" }}
                          onClick={() => setIsMobile(false)}
                        >
                          <a>
                            <i className="bx bx-right-arrow-alt" />
                            Auto Sell Token Report
                          </a>
                        </Link>
                      </li>
                    </ul>
                  </li>
                  <li className="dropdown open5">
                    <a href="#" className="dropbtn">
                      {" "}
                      <i className="bx bx-message-square-edit" />
                      Genealogy
                    </a>
                    <ul className="dropdown-content closenav5">
                      <li>
                        <Link
                          to="/Tree_View"
                          style={{ textDecoration: "none", padding: "0" }}
                          onClick={() => setIsMobile(false)}
                        >
                          <a>
                            <i className="bx bx-right-arrow-alt" />
                            Tree View
                          </a>
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/Direct_User_List"
                          style={{ textDecoration: "none", padding: "0" }}
                          onClick={() => setIsMobile(false)}
                        >
                          <a>
                            <i className="bx bx-right-arrow-alt" />
                            Direct User List
                          </a>
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/Level_Details"
                          style={{ textDecoration: "none", padding: "0" }}
                          onClick={() => setIsMobile(false)}
                        >
                          <a>
                            <i className="bx bx-right-arrow-alt" />
                            Level Details
                          </a>
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/Downline"
                          style={{ textDecoration: "none", padding: "0" }}
                          onClick={() => setIsMobile(false)}
                        >
                          <a>
                            <i className="bx bx-right-arrow-alt" />
                            Downline
                          </a>
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/Matrix_Tree"
                          style={{ textDecoration: "none", padding: "0" }}
                          onClick={() => setIsMobile(false)}
                        >
                          <a href="Matrix_Tree.html">
                            <i className="bx bx-right-arrow-alt" />
                            Matrix Tree
                          </a>
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/Matrix_Downline"
                          style={{ textDecoration: "none", padding: "0" }}
                          onClick={() => setIsMobile(false)}
                        >
                          <a href="Matrix_Downline.html">
                            <i className="bx bx-right-arrow-alt" />
                            Matrix Downline
                          </a>
                        </Link>
                      </li>
                    </ul>
                  </li>
                  <li className="dropdown open6">
                    <a href="#" className="dropbtn">
                      {" "}
                      <i className="bx bx-message-square-edit" />
                      Reports
                    </a>
                    <ul className="dropdown-content closenav6">
                      <li>
                        <Link
                          to="/Direct_Earning_Income"
                          style={{ textDecoration: "none", padding: "0" }}
                          onClick={() => setIsMobile(false)}
                        >
                          <a>
                            <i className="bx bx-right-arrow-alt" />
                            Direct Earning
                          </a>
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/Binary_Earning_Report"
                          style={{ textDecoration: "none", padding: "0" }}
                          onClick={() => setIsMobile(false)}
                        >
                          <a href="Binary_Earning_Report.html">
                            <i className="bx bx-right-arrow-alt" />
                            Binary Earning Report
                          </a>
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/Matrix_Earning"
                          style={{ textDecoration: "none", padding: "0" }}
                          onClick={() => setIsMobile(false)}
                        >
                          <a>
                            <i className="bx bx-right-arrow-alt" />
                            Matrix Earning
                          </a>
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/Matrix_CTO_Earning"
                          style={{ textDecoration: "none", padding: "0" }}
                          onClick={() => setIsMobile(false)}
                        >
                          <a>
                            <i className="bx bx-right-arrow-alt" />
                            Matrix CTO Earning
                          </a>
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/Withdrawal_Direct_Earning"
                          style={{ textDecoration: "none", padding: "0" }}
                          onClick={() => setIsMobile(false)}
                        >
                          <a>
                            <i className="bx bx-right-arrow-alt" />
                            Withdrawal Direct Earning
                          </a>
                        </Link>
                      </li>
                    </ul>
                  </li>
                  <li className="dropdown open9">
                  <a href="#" className="dropbtn">
                    <i className="bx bx-atom" />
                    Game
                  </a>
                  <ul className="dropdown-content closenav9">
                    <li>
                      <Link
                        to="/ColorGame"
                        style={{ textDecoration: "none", padding: "0" }}
                        onClick={() => setcheckState(true)}
                      >
                        <a>
                          <i className="bx bx-right-arrow-alt" />
                          Color Game
                        </a>
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/WithrawalToGameWallet"
                        style={{ textDecoration: "none", padding: "0" }}
                        onClick={() => setcheckState(true)}
                      >
                        <a>
                          <i className="bx bx-right-arrow-alt" />
                          Withdrawal to Game Wallet
                        </a>
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/GameWalletHistory?type=1"
                        style={{ textDecoration: "none", padding: "0" }}
                        onClick={() => setcheckState(true)}
                      >
                        <a>
                          <i className="bx bx-right-arrow-alt" />
                          Withdrawal to Game Wallet Convert History
                        </a>
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/GameToWithrawalWallet"
                        style={{ textDecoration: "none", padding: "0" }}
                        onClick={() => setcheckState(true)}
                      >
                        <a>
                          <i className="bx bx-right-arrow-alt" />
                          Game to Withdrawal Wallet
                        </a>
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/GameWalletHistory?type=2"
                        style={{ textDecoration: "none", padding: "0" }}
                        onClick={() => setcheckState(true)}
                      >
                        <a>
                          <i className="bx bx-right-arrow-alt" />
                           Game To Withdrawal Wallet Convert History
                        </a>
                      </Link>
                    </li>
                  </ul>
                </li>
                  <li className="dropdown open10">
                    <a href="#" className="dropbtn">
                      {" "}
                      <i className="bx bx-message-square-edit" />
                      Withdrawal
                    </a>
                    <ul className="dropdown-content closenav10">
                      <li>
                        <Link
                          to="/Withdrawal"
                          style={{ textDecoration: "none", padding: "0" }}
                          onClick={() => setIsMobile(false)}
                        >
                          <a>
                            <i className="bx bx-right-arrow-alt" />
                            Withdrawal
                          </a>
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/Withdrawal_History"
                          style={{ textDecoration: "none", padding: "0" }}
                          onClick={() => setIsMobile(false)}
                        >
                          <a href="Withdrawal_History.html">
                            <i className="bx bx-right-arrow-alt" />
                            Withdrawal History
                          </a>
                        </Link>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <Link
                      to="/"
                      style={{ textDecoration: "none", padding: "0" }}
                      onClick={() => setIsMobile(false)}
                    >
                      <a href="Login.html">
                        <i className="bx bx-lock" />
                        Sign Out
                      </a>
                    </Link>
                  </li>
                </ul>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
