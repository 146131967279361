import React from "react";
import { Blocks } from "react-loader-spinner";
import "./Spinner.css";

export default function Spinner() {
  return (
    <div className="LoaderSpinner">
      <Blocks
  height="100"
  width="100"
  color="#247b0e"
  ariaLabel="blocks-loading"
  wrapperStyle={{}}
  wrapperClass="blocks-wrapper"
  visible={true}
  />
    </div>
  );
}
