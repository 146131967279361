import user3red from "../../Assets/TreeImage/redicon.png";
import Default from "../../Assets/TreeImage/blackicon.png";
import Active from "../../Assets/TreeImage/greenicon.png";
import tree from "../../Assets/TreeImage/treeimg.png";
import tree1 from "../../Assets/TreeImage/treeimg1.png";
import tree2 from "../../Assets/TreeImage/treeimg2.png";
import React, { useEffect, useState } from "react";
import { Image } from "react-bootstrap";

// import activehorse from "../../Assets/tree_green.png";
// import inactivehorse from "../../Assets/tree_red.png";
// import blackhorse from "../../Assets/tree_black.png";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Tree, TreeNode } from "react-organizational-chart";
import styled from "styled-components";
// import HoverTreeNode from "./HoverTreeNode";
import { useSelector } from "react-redux";
import { API } from "../../API/Api";
import toast from "react-hot-toast";
import DOMPurify from "dompurify";
import TreeHoverNode from "./TreeHoverNode";
var bol = true;

export default function NewTree() {
  const navigate = useNavigate();

  const user = useSelector((state) => state.Auth.userId);
  const jwt = useSelector((state) => state.Auth.jwt_token);
  // console.log("User",user);

  const [Idnumer, setIdnumer] = useState(user);
  const [arrValue, setArrValue] = useState([]);
  const [getValue, setgetValue] = useState("");
  const [loader, setloader] = useState(false);
  const [searchvalue, setsearchvalue] = useState(0);

  const [userdata, setuserdata] = useState([
    {
      name: "",
      id: "",
      registration_date: "",
      status: "",
      total_left: "",
      total_left_active: "",
      left_business: "",
      package_amount: "",
      Activation_date: "",
      package: "",
      total_right: "",
      total_right_active: "0",
      right_business: "",
      Sponsor: "",
    },
    {
      name: "",
      id: "",
      registration_date: "",
      status: "",
      total_left: "",
      total_left_active: "",
      left_business: "",
      package_amount: "",
      Activation_date: "",
      package: "",
      total_right: "",
      total_right_active: "",
      right_business: "",
      Sponsor: "",
    },
    {
      name: "",
      id: "",
      registration_date: "",
      status: "",
      total_left: "",
      total_left_active: "",
      left_business: "",
      package_amount: "",
      Activation_date: "",
      package: "",
      total_right: "",
      total_right_active: "",
      right_business: "",
      Sponsor: "",
    },
    {
      name: "",
      id: "",
      registration_date: "",
      status: "",
      total_left: "",
      total_left_active: "",
      left_business: "",
      package_amount: "",
      Activation_date: "",
      package: "",
      total_right: "",
      total_right_active: "",
      right_business: "",
      Sponsor: "",
    },
    {
      name: "",
      id: "",
      registration_date: "",
      status: "",
      total_left: "",
      total_left_active: "",
      left_business: "",
      package_amount: "",
      Activation_date: "",
      package: "",
      total_right: "",
      total_right_active: "",
      right_business: "",
      Sponsor: "",
    },
    {
      name: "",
      id: "",
      registration_date: "",
      status: "",
      total_left: "",
      total_left_active: "",
      left_business: "",
      package_amount: "",
      Activation_date: "",
      package: "",
      total_right: "",
      total_right_active: "",
      right_business: "",
      Sponsor: "",
    },
    {
      name: "",
      id: "",
      registration_date: "",
      status: "",
      total_left: "",
      total_left_active: "",
      left_business: "",
      package_amount: "",
      Activation_date: "",
      package: "",
      total_right: "",
      total_right_active: "",
      right_business: "",
      Sponsor: "",
    },
    {
      name: "",
      id: "",
      registration_date: "",
      status: "",
      total_left: "",
      total_left_active: "",
      left_business: "",
      package_amount: "",
      Activation_date: "",
      package: "",
      total_right: "",
      total_right_active: "",
      right_business: "",
      Sponsor: "",
    },
    {
      name: "",
      id: "",
      registration_date: "",
      status: "",
      total_left: "",
      total_left_active: "",
      left_business: "",
      package_amount: "",
      Activation_date: "",
      package: "",
      total_right: "",
      total_right_active: "",
      right_business: "",
      Sponsor: "",
    },
    {
      name: "",
      id: "",
      registration_date: "",
      status: "",
      total_left: "",
      total_left_active: "",
      left_business: "",
      package_amount: "",
      Activation_date: "",
      package: "",
      total_right: "",
      total_right_active: "",
      right_business: "",
      Sponsor: "",
    },
    {
      name: "",
      id: "",
      registration_date: "",
      status: "",
      total_left: "",
      total_left_active: "",
      left_business: "",
      package_amount: "",
      Activation_date: "",
      package: "",
      total_right: "",
      total_right_active: "",
      right_business: "",
      Sponsor: "",
    },
    {
      name: "",
      id: "",
      registration_date: "",
      status: "",
      total_left: "",
      total_left_active: "",
      left_business: "",
      package_amount: "",
      Activation_date: "",
      package: "",
      total_right: "",
      total_right_active: "",
      right_business: "",
      Sponsor: "",
    },
    {
      name: "",
      id: "",
      registration_date: "",
      status: "",
      total_left: "",
      total_left_active: "",
      left_business: "",
      package_amount: "",
      Activation_date: "",
      package: "",
      total_right: "",
      total_right_active: "",
      right_business: "",
      Sponsor: "",
    },
    {
      name: "",
      id: "",
      registration_date: "",
      status: "",
      total_left: "",
      total_left_active: "",
      left_business: "",
      package_amount: "",
      Activation_date: "",
      package: "",
      total_right: "",
      total_right_active: "",
      right_business: "",
      Sponsor: "",
    },
    {
      name: "",
      id: "",
      registration_date: "",
      status: "",
      total_left: "",
      total_left_active: "",
      left_business: "",
      package_amount: "",
      Activation_date: "",
      package: "",
      total_right: "",
      total_right_active: "",
      right_business: "",
      Sponsor: "",
    },
  ]);
  const referral_API = async () => {
    try {
      let responce = await API?.post(
        "/binaryTree",
        {
          searchId: Idnumer,
          loginId: user,
        },
        {
          headers: {
            Authorization: `${jwt}`,
            "Content-Type": "application/json", // Adjust the content type as needed
          },
        }
      );
      responce = responce?.data?.data[0];
      console.log("Res_API_binaryTree", responce);
      if (responce == undefined) {
        toast.error("User ID Not Found");
        setloader(false);
      } else {
        setloader(true);

        let arr = [];
        responce.forEach((item, index) => {
          arr.push({
            name: item.fname,
            id: item.uid,
            registration_date: String(item?.Regdate).substring(0, 12),
            status: item.activationdate ? "Active" : "InActive",
            total_left: item.T_Left,
            total_left_active: item.totalleft,
            left_business: item.lbv,
            package_amount: item.T_package,
            Activation_date: String(item.activationdate).substring(0, 12),
            package: item.packageamount,
            total_right: item.T_Right,
            total_right_active: item.totalright,
            right_business: item.rbv,
            Sponsor: item.sid,
            date: item?.packageName,
            // country: item.countryname,
            today_left_business: item.T_LeftB,
            today_right_business: item.T_RightB,
            // left_carry_forward: item.carryleft,
            // right_carry_forward: item.carryright,
            // rank: item.rewardrankname,
          });
        });
        // console.log("responce", arr);

        // setloader(false);

        setuserdata(arr);
        if (bol) {
          setArrValue([...arrValue, arr[0].id]);
          bol = false;
        }
      }
    } catch (e) {
      // setloader(false);
      console.log("Error While calling Referrer API", e);
    }
  };
  function addValue(value) {
    setArrValue([...arrValue, value]);
    // arrValue.push(value)
    // arrValue.push(value)
  }

  var a;
  function popoutvalue() {
    if (arrValue.length == 1) {
      // setloader(true)
      arrValue.pop();
      arrValue.unshift(user);
      bol = true;
    } else {
      a = arrValue.splice(arrValue.length - 2, 1);
      setIdnumer(a[0]);
    }
  }

  useEffect(() => {
    referral_API();
    let idinput = document.querySelector(".idinput");
    idinput.value = Idnumer;
  }, [Idnumer]);

  const StyledNode = styled.div`
    padding: 5px;
    display: inline-block;
    position: relative;
    &:hover .HoverNodeItemMain {
      display: block;
    }
  `;
  const HoverNode = styled.div`
    /* other styles */
    &.marginLeft {
      margin-left: -300px;
    }
  `;

  return (
    <div>
      <div className="page-wrapper">
        <div className="page-content pb-5 treeviewpage">
          <div className="row">
            <div class="col-lg-6">
              <div class="card">
                <div class="card-body">
                  <div class="form-wrapper">
                    <input
                      class="form-control idinput srch_frm bg-white border-0 shadow-none text-black"
                      id="ddluserid"
                      name="userId"
                      type="text"
                      placeholder="Search"
                      Value={Idnumer}
                      onChange={(e) =>
                        setgetValue(
                          DOMPurify.sanitize(
                            e.target.value
                              .replace(/[^0-9]/gi, "")
                              .substring(0, 8)
                          )
                        )
                      }
                    />
                    <input
                      type="submit"
                      value="SUBMIT"
                      id="Search"
                      onClick={() => (setIdnumer(getValue), addValue(getValue))}
                      class="btn btn-sm btn-theme2 submit_btn text-white border-0 shadow-none px-3"
                    />
                    <button
                      id="#home"
                      type="button"
                      onClick={() => setIdnumer(user)}
                      class="btn btn-sm btn-danger border-0 shadow-none px-3"
                    >
                      Home
                    </button>
                    <button
                      onClick={popoutvalue}
                      type="button"
                      class="btn btn-sm btn-danger shadow-none border-0 px-3"
                    >
                      BACK
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="card">
                <div className="card-body">
                  <div className="tree_icon">
                    <div className="tree_name">
                      <img src={Active} width="40px" height="40px" />
                      Active
                    </div>
                    <div className="tree_name">
                      <img src={Default} width="40px" height="40px" />
                      In-Active
                    </div>
                    <div className="tree_name">
                      <img src={user3red} width="40px" height="40px" />
                      Not Registered
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-12">
              <div className="card">
                <div
                  id="tree"
                  className="treeview"
                  style={{ margin: "30px auto" }}
                >
                  <div className="treemember">
                    <div className="dropdown">
                      <button className="dropbtn">
                        <img
                          src={
                            userdata[0].package >= 1
                              ? Active
                              : userdata[0].id == ""
                              ? Default
                              : user3red
                          }
                          alt="Image description"
                          fluid={true}
                          onClick={() => (
                            setIdnumer(userdata[0].id), addValue(userdata[0].id)
                          )}
                          className="abc"
                        />
                      </button>
                      <div className="span text-white">
                        <br/>
                        {userdata[0]?.id}</div>
                      <TreeHoverNode data={userdata[0]} />
                    </div>
                  </div>
                  <div className="connecter1">
                    <img src={tree} style={{ width: 480, height: 33 }} />
                  </div>
                  <div className="tree_row8">
                    <div className="row_8_child">
                      <div className="dropdown">
                        <button className="dropbtn">
                          <img
                            src={
                              userdata[1].package >= 1
                                ? Active
                                : userdata[1].id == ""
                                ? Default
                                : user3red
                            }
                            alt="Image description"
                            fluid={true}
                            onClick={() => (
                              setIdnumer(userdata[1].id),
                              addValue(userdata[1].id)
                            )}
                            className="abc"
                          />
                        </button>
                        <div className="span text-white">
                          {userdata[1]?.id}
                          <br/>
                          
                          </div>
                        <TreeHoverNode data={userdata[1]} />
                      </div>
                    </div>
                    <div className="row_8_child">
                      
                       
                    <div className="dropdown">
                        <button className="dropbtn">
                          <img
                            src={
                              userdata[2].package >= 1
                                ? Active
                                : userdata[2].id == ""
                                ? Default
                                : user3red
                            }
                            alt="Image description"
                            fluid={true}
                            onClick={() => (
                              setIdnumer(userdata[2].id),
                              addValue(userdata[2].id)
                            )}
                            className="abc"
                          />
                        </button>
                        <div className="span text-white">
                          
                          {userdata[2]?.id}</div>
                        <TreeHoverNode data={userdata[2]} />
                      </div>
                    </div>
                  </div>
                  <div className="tree_row9" style={{ height: 34,marginTop:"1rem" }}>
                    <div className="row_9_child" style={{ height: 34 }}>
                      <img src={tree1} style={{ width: 253, height: 32 }} />
                    </div>
                    <div className="row_9_child" style={{ height: 34 }}>
                      <img src={tree1} style={{ width: 253, height: 32 }} />
                    </div>
                  </div>
                  <div className="tree_row10">
                    <div className="row_10_child">
                    <div className="dropdown">
                        <button className="dropbtn">
                          <img
                            src={
                              userdata[3].package >= 1
                                ? Active
                                : userdata[3].id == ""
                                ? Default
                                : user3red
                            }
                            alt="Image description"
                            fluid={true}
                            onClick={() => (
                              setIdnumer(userdata[3].id),
                              addValue(userdata[3].id)
                            )}
                            className="abc"
                          />
                        </button>
                        <div className="span text-white">
                          
                          {userdata[3]?.id}</div>
                        <TreeHoverNode data={userdata[3]} />
                      </div>
                    </div>
                    <div className="row_10_child">
                    <div className="dropdown">
                        <button className="dropbtn">
                          <img
                            src={
                              userdata[4].package >= 1
                                ? Active
                                : userdata[4].id == ""
                                ? Default
                                : user3red
                            }
                            alt="Image description"
                            fluid={true}
                            onClick={() => (
                              setIdnumer(userdata[4].id),
                              addValue(userdata[4].id)
                            )}
                            className="abc"
                          />
                        </button>
                        <div className="span text-white">
                          
                          {userdata[4]?.id}</div>
                        <TreeHoverNode data={userdata[4]} />
                      </div>
                    </div>
                    <div className="row_10_child">
                    <div className="dropdown">
                        <button className="dropbtn">
                          <img
                            src={
                              userdata[5].package >= 1
                                ? Active
                                : userdata[5].id == ""
                                ? Default
                                : user3red
                            }
                            alt="Image description"
                            fluid={true}
                            onClick={() => (
                              setIdnumer(userdata[5].id),
                              addValue(userdata[5].id)
                            )}
                            className="abc"
                          />
                        </button>
                        <div className="span text-white">
                          
                          {userdata[5]?.id}</div>
                        <TreeHoverNode data={userdata[5]} />
                      </div>
                    </div>
                    <div className="row_10_child">
                    <div className="dropdown">
                        <button className="dropbtn">
                          <img
                            src={
                              userdata[6].package >= 1
                                ? Active
                                : userdata[6].id == ""
                                ? Default
                                : user3red
                            }
                            alt="Image description"
                            fluid={true}
                            onClick={() => (
                              setIdnumer(userdata[6].id),
                              addValue(userdata[6].id)
                            )}
                            className="abc"
                          />
                        </button>
                        <div className="span text-white">
                          
                          {userdata[6]?.id}</div>
                        <TreeHoverNode data={userdata[6]} />
                      </div>
                    </div>
                  </div>
                  <div className="tree_row11" style={{ height: 27,marginTop:"1rem" }}>
                    <div className="row_11_child" style={{ height: 27 }}>
                      <img src={tree2} style={{ width: 129, height: 27 }} />
                    </div>
                    <div className="row_11_child" style={{ height: 27 }}>
                      <img src={tree2} style={{ width: 129, height: 27 }} />
                    </div>
                    <div className="row_11_child" style={{ height: 27 }}>
                      <img src={tree2} style={{ width: 129, height: 27 }} />
                    </div>
                    <div className="row_11_child" style={{ height: 27 }}>
                      <img src={tree2} style={{ width: 129, height: 27 }} />
                    </div>
                  </div>
                  <div className="tree_row12">
                    <div className="row_12_child" style={{}}>
                      <div className="dropdown">
                        <button className="dropbtn">
                          <img
                            src={
                              userdata[7].package >= 1
                                ? Active
                                : userdata[7].id == ""
                                ? Default
                                : user3red
                            }
                            alt="Image description"
                            fluid={true}
                            onClick={() => (
                              setIdnumer(userdata[7].id),
                              addValue(userdata[7].id)
                            )}
                            className="abc"
                          />
                        </button>
                        <div className="span text-white">
                          
                          {userdata[7]?.id}</div>
                        <TreeHoverNode data={userdata[7]} />
                      </div>
                    </div>
                    <div className="row_12_child" style={{}}>
                      <div className="dropdown">
                        <button className="dropbtn">
                          <img
                            src={
                              userdata[8].package >= 1
                                ? Active
                                : userdata[8].id == ""
                                ? Default
                                : user3red
                            }
                            alt="Image description"
                            fluid={true}
                            onClick={() => (
                              setIdnumer(userdata[8].id),
                              addValue(userdata[8].id)
                            )}
                            className="abc"
                          />
                        </button>
                        <div className="span text-white">
                          
                          {userdata[8]?.id}</div>
                        <TreeHoverNode data={userdata[8]} />
                      </div>
                    </div>
                    <div className="row_12_child" style={{}}>
                    <div className="dropdown">
                        <button className="dropbtn">
                          <img
                            src={
                              userdata[9].package >= 1
                                ? Active
                                : userdata[9].id == ""
                                ? Default
                                : user3red
                            }
                            alt="Image description"
                            fluid={true}
                            onClick={() => (
                              setIdnumer(userdata[9].id),
                              addValue(userdata[9].id)
                            )}
                            className="abc"
                          />
                        </button>
                        <div className="span text-white">
                          
                          {userdata[9]?.id}</div>
                        <TreeHoverNode data={userdata[9]} />
                      </div>
                    </div>
                    <div className="row_12_child" style={{}}>
                    <div className="dropdown">
                        <button className="dropbtn">
                          <img
                            src={
                              userdata[10].package >= 1
                                ? Active
                                : userdata[10].id == ""
                                ? Default
                                : user3red
                            }
                            alt="Image description"
                            fluid={true}
                            onClick={() => (
                              setIdnumer(userdata[10].id),
                              addValue(userdata[10].id)
                            )}
                            className="abc"
                          />
                        </button>
                        <div className="span text-white">
                          
                          {userdata[10]?.id}</div>
                        <TreeHoverNode data={userdata[10]} />
                      </div>
                    </div>
                    <div className="row_12_child" style={{}}>
                      <div className="dropdown">
                        <button className="dropbtn">
                          <img
                            src={
                              userdata[11].package >= 1
                                ? Active
                                : userdata[11].id == ""
                                ? Default
                                : user3red
                            }
                            alt="Image description"
                            fluid={true}
                            onClick={() => (
                              setIdnumer(userdata[11].id),
                              addValue(userdata[11].id)
                            )}
                            className="abc"
                          />
                        </button>
                        <div className="span text-white">
                          
                          {userdata[11]?.id}</div>
                        <TreeHoverNode data={userdata[11]} />
                      </div>
                    </div>
                    <div className="row_12_child" style={{}}>
                      <div className="dropdown">
                        <button className="dropbtn">
                          <img
                            src={
                              userdata[12].package >= 1
                                ? Active
                                : userdata[12].id == ""
                                ? Default
                                : user3red
                            }
                            alt="Image description"
                            fluid={true}
                            onClick={() => (
                              setIdnumer(userdata[12].id),
                              addValue(userdata[12].id)
                            )}
                            className="abc"
                          />
                        </button>
                        <div className="span text-white">
                          
                          {userdata[12]?.id}</div>
                        <TreeHoverNode data={userdata[12]} />
                      </div>
                    </div>
                    <div className="row_12_child" style={{}}>
                    <div className="dropdown">
                        <button className="dropbtn">
                          <img
                            src={
                              userdata[13].package >= 1
                                ? Active
                                : userdata[13].id == ""
                                ? Default
                                : user3red
                            }
                            alt="Image description"
                            fluid={true}
                            onClick={() => (
                              setIdnumer(userdata[13].id),
                              addValue(userdata[13].id)
                            )}
                            className="abc"
                          />
                        </button>
                        <div className="span text-white">
                          
                          {userdata[13]?.id}</div>
                        <TreeHoverNode data={userdata[13]} />
                      </div>
                    </div>
                    <div className="row_12_child" style={{}}>
                    <div className="dropdown">
                        <button className="dropbtn">
                          <img
                            src={
                              userdata[14].package >= 1
                                ? Active
                                : userdata[14].id == ""
                                ? Default
                                : user3red
                            }
                            alt="Image description"
                            fluid={true}
                            onClick={() => (
                              setIdnumer(userdata[14].id),
                              addValue(userdata[14].id)
                            )}
                            className="abc"
                          />
                        </button>
                        <div className="span text-white">
                          
                          {userdata[14]?.id}</div>
                        <TreeHoverNode data={userdata[14]} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
