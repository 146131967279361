import React, { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { UpdateAuth } from "../../Redux/AuthSlice";
import { API } from "../../API/Api";
import axios from "axios";
import { useDispatch } from "react-redux";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { loadWeb3 } from "../../API/contract";
import "./style.css";
import DOMPurify from "dompurify";

export default function Registration() {
  const [searchParams, setSearchParams] = useSearchParams();
  const [sponser, setSponser] = useState(searchParams.get("userid"));
  const [selectPosition, setselectPosition] = useState(
    searchParams.get("position") === "Left"
      ? 1
      : searchParams.get("position") === "Right"
      ? 2
      : ""
  );

  let navigate = useNavigate();
  const [registor_Model, setregistor_Model] = useState(false);
  const [connected, setconnected] = useState(true);
  const [sid, setSid] = useState(sponser);
  const [walletAddress, setWalletAddress] = useState("");
  const [LoginId, setLoginId] = useState();

  // console.log("sid", sid);
  const dispatch = useDispatch();

  const walletConnected = async () => {
    try {
      let acc = await loadWeb3();
      let response = await axios.get("https://api64.ipify.org/?format=json");
      console.log("response Geolocation", response.data.ip);
      // setIP(response.data.ip);
      let ipaddress = response.data.ip;

      if (acc === "No Wallet" || acc === undefined) {
        toast.error("No Wallet");
        setconnected("Wallet is locked");
      } else if (acc === "Wrong Network") {
        toast.error(" Wrong Network Please Connect BSc Network");
        console.log("Wrong Network Please Connect BSC Scan Network");
        setconnected("Wrong Network");
      } else {
        // setaddress(acc)
        setconnected("Wallet is Connected");
        // setLoginId("0X484Demo848sdcssd48");
        setLoginId(acc);
        let res = await API.post(`/userlogin`, {
          password: acc,
          ipaddress: ipaddress,
        });
        let UserID = res.data.data;
        if (res.data.data.result === "Successfull") {
          toast.success(`Login Successful`);
          dispatch(
            UpdateAuth({
              isAuth: true,
              userId: res.data.data.uid_output,
              jwt_token: res.data.token,
            })
          );
          navigate("/DashBoard");
        } else {
          toast.error(res.data.data.result);
        }
      }
    } catch (e) {
      console.log("Error While WalletConnect", e);
    }
  };

  const registerUser = async () => {
    let acc = await loadWeb3();

    if (!acc) {
      setconnected("Wallet is locked");
    } else if (acc === "No Wallet" || acc === undefined) {
      toast.error("No Wallet");
      setconnected("Wallet is locked");
    } else if (acc == "Wrong Network") {
      toast.error(" Wrong Network Please Connect BSc Network");
      setconnected("Wrong Network");
    } else {
      try {
        if (selectPosition === "" || selectPosition === undefined) {
          toast.error("Select Position!!");
        }
        if (sid === "" || sid === undefined) {
          toast.error("Enter Upline Id");
        }
        let res = await API.post("registration", {
          sid: sid,
          walletAddress: acc,
          position: selectPosition,
        });
        // console.log("registration", res.data);
        if (res.data.data.result === "Successfull") {
          toast.success("Register Successfull");
          let res = await API.post(`/userlogin`, {
            password: acc,
          });

          let UserID = res.data.data;
          if (res.data.data.result === "Successfull") {
            toast.success(`Login Successful`);
            dispatch(
              UpdateAuth({
                isAuth: true,
                userId: res.data.data.uid_output,
                jwt_token: res.data.token,
              })
            );
            navigate("/DashBoard");
          } else {
            toast.error(`Invalid User id or Address`);
            // setspinnerload(false);
          }

          //setspiner(false);
        } else {
          toast.error(`${res.data.data.result}`);
          //setspiner(true);
        }
      } catch (e) {
        console.log("Error while regiater", e);
      }
    }
  };

  useEffect(() => {
    walletConnected();
  }, []);

  return (
    <div className="bgimage">
      <div className="container-fluid">
        <div className="container vh-100 ">
          <div className="row align-items-center justify-content-center h-100">
            <div className="col-sm-4">
              <div className="card" style={{ border: "2px solid #8bcf00" }}>
                <div
                  className="card-body"
                  style={{ backgroundColor: "#00610047" }}>
                  <div className="row mx-0 align-items-center justify-content-center gy-3">
                    <div className="col-12">
                      <div className="logoimage mx-auto">
                        <img
                          className="w-100 h-100"
                          src="./assets/images/logo.png"
                          alt=""
                        />
                      </div>
                    </div>
                    <div className="col-12 text-center">
                      <div className="loginheading">
                        Access Your Personal Account
                      </div>
                      <div
                        className="subtext"
                        style={{
                          color:
                            connected === "Wallet is Connected"
                              ? "White"
                              : "red",
                        }}>
                        {connected}
                      </div>
                    </div>
                    <div className="col-12 text-center">
                      <button
                        className="btn btnlogin px-4"
                        onClick={() => setregistor_Model(true)}>
                        Register
                      </button>
                    </div>
                    <div className="col-12">
                      <div className="remembertext">
                        Remember to authorize with the correct address.View an
                        Account
                      </div>
                    </div>
                    <div className="col-12">
                      <input
                        type="text"
                        className="control-form"
                        placeholder="Please Enter ID or Address"
                        value={LoginId}
                        readOnly
                      />
                    </div>
                    <div className="col-12 text-center">
                      <button className="btn btnlogin px-4">
                        Connect to Wallet
                      </button>
                    </div>
                    <div className="col-12">
                      <div className="remembertext">
                        Enter the account id or BNB Address
                      </div>
                    </div>
                    <div
                      id="myModal"
                      className="modal"
                      style={{ display: registor_Model ? "block" : "none" }}>
                      <div className="modal-content-register boxset">
                        <h4>Enter Upline Id</h4>
                        <br />
                        <p>
                          {" "}
                          <input
                            id="txtsid"
                            // className="input-id"
                            className="control-form"
                            value={sid}
                            maxLength={8}
                            type="text"
                            min={0}
                            placeholder="Enter Upline Id"
                            onChange={(e) => {
                              const inputText = e.target.value
                                .replace(/[^0-9]/gi, "")
                                .substring(0, 8);
                              const sanitizedInput =
                                DOMPurify.sanitize(inputText);
                              setSid(sanitizedInput);
                            }}
                          />
                        </p>
                        <select
                          className="form-control"
                          id="__BVID__17"
                          value={selectPosition}
                          onChange={(e) => setselectPosition(e.target.value)}>
                          <option selected>Please Select Position</option>
                          <option value="1">Left</option>
                          <option value="2">Right</option>
                        </select>
                        <br />
                        <div className="rerlbtn">
                          <button
                            className=" btn_Model"
                            onClick={() => {
                              registerUser();
                            }}>
                            Register
                          </button>

                          <button
                            className="mx-2 btn_Model"
                            onClick={() => setregistor_Model(false)}>
                            Close
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="remembertext2">
                        If you have already registered then
                        <Link to="/" style={{ color: "#eaef09" }}>
                          {" "}
                          Login
                        </Link>
                      </div>
                      <div className="remembertext2">
                        Copyright © 2024. All Rights Reserved.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
