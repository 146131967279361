import React, { useEffect, useState } from "react";
import { API } from "../API/Api";
import ShareTable from "../Components/Table/Table";
import { useSelector } from "react-redux";
import DOMPurify from "dompurify";

const columns = [
  {
    dataField: "Number",
    text: "S.No",
    sort: false,
  },

  {
    dataField: "User_ID",
    text: "User ID",
    sort: false,
  },
  {
    dataField: "Wallet",
    text: "Wallet",
    sort: false,
  },
  {
    dataField: "amount",
    text: "Amount",
    sort: false,
  },

  {
    dataField: "Status",
    text: "Remark",
    sort: false,
  },
  {
    dataField: "Activation_Date",
    text: "Date",
    sort: false,
  },
];

export default function TotalDownlineReport() {
  const [dataArray, setdataArray] = useState([]);
  const user = useSelector((state) => state.Auth.userId);
  const jwt = useSelector((state) => state.Auth.jwt_token);
  const [currentPageSize, setCurrentPageSize] = useState(10);
  const [search, setSearch] = useState("");
  const [position, setPosition] = useState(0);
  const [StatusFilter, setStatusFilter] = useState(2);
  const [positionfilter, setpositionfilter] = useState(0);
  console.log("token", jwt);
  const Downline_API = async () => {
    try {
      let responce = await API?.post(
        `/UserDownlineReport`,
        {
          uid: user,
          type: 2,
        },
        {
          headers: {
            Authorization: `${jwt}`,
            "Content-Type": "application/json",
          },
        }
      );
      responce = responce?.data?.data;
      console.log("myDownline", responce);

      let arr = [];
      responce.forEach((item, index) => {
        arr.push({
          Number: item?.rownumber,
          Registration_Date: item?.registrationDate,
          User_ID: item?.uid,
          Position: item?.position,
          Wallet: `${item?.wallet_address?.substring(
            0,
            6
          )}...${item?.wallet_address?.substring(
            item?.wallet_address?.length - 4
          )}`,
          Status: (
            <button
              type="button"
              className={`btn1 active_btn ${
                item.remark == "Activate" ? "green" : "green"
              }`}
            >
              {item?.remark}
            </button>
          ),
          amount: item?.amount,
          Activation_Date: item?.edate == null ? "Null" : item?.edate,
        });
      });

      setdataArray(arr);
    } catch (e) {
      console.log("Error While calling Referrer API", e);
    }
  };

  const handleUserIdChange = (e) => {
    const inputText = e.target.value.replace(/[^0-9]/gi, "").substring(0, 7);
    const sanitizeValue = DOMPurify.sanitize(inputText);
    setSearch(sanitizeValue);
  };

  const handlePositionChange = (value) => {
    setPosition(value);
  };

  const handlePageSizeChange = (size) => {
    setCurrentPageSize(size);
  };

  useEffect(() => {
    Downline_API();
  }, []);

  return (
    <div>
      <div className="page-wrapper">
        <div className="page-content adjustmentsection">
          {/*breadcrumb*/}
          <div className="row mx-0  py-3">
            <div className="col-12">
              <div className="d-flex align-items-center justify-content-between">
                <div className="fs-18 text-white fw-bold">
                  Total Downline Report
                </div>
                <div className="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
                  <div className="breadcrumb-title pe-3">Genealogy</div>
                  <div className="ps-3">
                    <nav aria-label="breadcrumb">
                      <ol className="breadcrumb mb-0 p-0">
                        <li className="breadcrumb-item">
                          <a href="javascript:;">
                            <i className="bx bx-home-alt text-white" />
                          </a>
                        </li>
                        <li
                          className="breadcrumb-item active"
                          aria-current="page"
                        >
                          Total Downline Report
                        </li>
                      </ol>
                    </nav>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <div className="row mx-0 mb-3">
            <div className="col-md-3">
              <label className="text-white fs-14">User Id</label>
              <input
                type="text"
                name="from_date"
                id="from_date"
                className="form-control text-white shadow-none bgmain"
                placeholder="User ID"
                value={search}
                onChange={handleUserIdChange}
              />
            </div>
            <br />
            <br />
            <div className="col-md-3">
              <label className="text-white fs-14">Choose Position</label>
              <select
                className="form-control shadow-none bgmain"
                id="status"
                style={{ color: "#fff" }}
                value={position}
                onChange={(e) => handlePositionChange(e.target.value)}
              >
                <option style={{ color: "#fff" }} value={0}>
                  All
                </option>
                <option style={{ color: "#fff" }} value={1}>
                  Left
                </option>
                <option style={{ color: "#fff" }} value={2}>
                  Right
                </option>
              </select>
            </div>
            <br />
            <br />
            <div className="col-md-auto mt-1">
              <input
                type="submit"
                name="to_date"
                defaultValue="search"
                value="Search"
                className="btn btn-primary mt_5 bgmain border-0 px-3 shadow-none w-auto"
                onClick={() => Downline_API()}
              />
            </div>
          </div> */}
          <div className="row mx-0">
            <div className="col-12">
              <div className="card bg-transparent mb-0 py-2">
                <div className="card-body">
                  <div className="row mx-0">
                    <ShareTable
                      columns={columns}
                      Data={dataArray}
                      defaultPageSize={currentPageSize}
                      pageSizeOptions={["10", "50", "100"]}
                      onPageSizeChange={handlePageSizeChange}
                      style={{ width: "100%" }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/*end row*/}
        </div>
      </div>
    </div>
  );
}
