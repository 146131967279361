import axios from "axios";
import React, { useEffect, useState } from "react";
import { UpdateAuth, updateStatus } from "../../Redux/AuthSlice";
import { API } from "../../API/Api";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { loadWeb3 } from "../../API/contract";
import Sell_Rate_Chart from "../../Pages/Sell_Rate_Chart";
import BuysellDashboard from "../../Pages/BuysellDashboard";

export default function DashBoard() {
  const user = useSelector((state) => state.Auth.userId);
  const sec = useSelector((state) => state.Auth.jwt_token);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  let URL = window.location.origin;
  const LeftCopyAddressHandler = () => {
    const text = `${URL}/Registration?userid=${user}&position=Left`;
    //  `${URL}/registration?userid=${user}`
    navigator.clipboard.writeText(text);
    toast.success("Copied");
  };

  const RightCopyAddressHandler = () => {
    const text = `${URL}/Registration?userid=${user}&position=Right`;

    navigator.clipboard.writeText(text);
    toast.success("Copied");
  };
  //  const dispatch = useDispatch();
  // console.log("Uid", user);
  const [dash, setdataArray] = useState([]);
  const [EarnPer, setEarnPer] = useState("0%");
  const [EarnPerT, setEarnPerT] = useState("0%");
  const [ip, setIp] = useState("");
  const [walletAddress, setwalletAddress] = useState("ConnectWallet");

  const Dashboard_API = async () => {
    try {
      let acc = await loadWeb3();
      setwalletAddress(acc);
      let responce = await API?.get(`userDetails?uid=${user}`, {
        headers: {
          Authorization: `${sec}`,
          "Content-Type": "application/json",
        },
      });
      responce = responce.data.data[0][0];
      // console.log("responceDashboard", responce);
      setEarnPer(responce.EarnPer + "%");
      setEarnPerT(responce.EarnPerT + "%");
      // console.log("dboard", responce);
      setdataArray(responce);
      if (responce.CurrentPackage > 0) {
        dispatch(updateStatus({ status: "Active" }));
      }
      let res = await API.post(
        "/matrixIncome",
        {
          uid: user,
          fdate: "",
          tdate: "",
          level: 0,
          package: 0,
        },
        {
          headers: {
            Authorization: `${sec}`,
            "Content-Type": "application/json", // Adjust the content type as needed
          },
        }
      );
      // console.log("resmatrixIncome", res);
    } catch (e) {
      console.log("Error While calling dashboard API", e);
      if (
        e.response.data.showableMessage ==
        "Please login to access this resource"
      ) {
        dispatch(UpdateAuth({ isAuth: false, userId: null, jwt_token: null }));
        navigate("/");
      }
    }
  };
  const AddressCopyAddressHandler = () => {
    const text = walletAddress;
    //  `${URL}/registration?userid=${user}`
    navigator.clipboard.writeText(text);
    toast.success("Copied");
  };

  const ipAddress = async () => {
    try {
      let response = await axios.get("https://api64.ipify.org/?format=json");
      // console.log("response Geolocation", response.data.ip);
      setIp(response.data.ip);
    } catch (e) {
      console.log("error", e);
    }
  };

  useEffect(() => {
    Dashboard_API();
    ipAddress();
  }, []);
  return (
    <div>
      <div className="page-wrapper">
        <div className="page-content">
          <div className="row">
            <div className="col-md-9">
              <div className="row">
                <div className="col-md-12">
                  <div className="nftHeading">
                    <h2 className="fs-18">DASHBOARD</h2>
                  </div>
                </div>
                <div className="col-md-6 pt-1">
                  <div className="popular_nfts mb22">
                    <img
                      src="assets/images/new_img/img1.jpg"
                      className="minHt"
                    />
                    <div className="nftTimeItem">
                      <h1 id="leftTime" />
                      <p>Welcome to the world of WYZZk ETH</p>
                      <p>
                        Click on the button below and share it with your people.
                      </p>
                      <div className="TotalEarnChatMain mt-3">
                        <div className="priceChart">
                          <span className="priceChart_text">
                            $ {dash?.TotalInvest}
                          </span>
                          <div className="slice">
                            <div className="bar" />
                            <div className="fill" />
                          </div>
                        </div>
                      </div>
                      <div className="d-sm-flex align-sm-items-center gap-3">
                        <button
                          type="button"
                          className="btn button btnhome w-50"
                          onClick={LeftCopyAddressHandler}>
                          Copy Left Referral Link
                        </button>
                        <button
                          type="button"
                          className="btn button btnhome w-50"
                          onClick={RightCopyAddressHandler}>
                          Copy Right Referral Link
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 pt-1">
                  <div className="popular_nfts ">
                    <img
                      src="assets/images/new_img/img1.jpg"
                      className="minHt secondminHt"
                    />
                    <div className="nftTimeItem">
                      <div className="d-sm-flex align-items-center justify-content-between gy-3">
                        <div className="">
                          <h1 id="leftTime2" />
                          <p className="bgcolor mb-2">
                            Binary 3x Capping ($ {dash.MaxIncome})
                          </p>
                          <div className="TotalEarnChatMain mt-3">
                            <div className="priceChart">
                              <span className="priceChart_text">
                                $ {dash.EarnAmount}
                              </span>
                              <div className="slice">
                                <div className="bar" />
                                <div className="fill" />
                              </div>
                            </div>
                          </div>
                          <h2>Status :{dash.EarnPer} %</h2>
                          {/* <p>Amount :$ {dash.EarnAmount}</p> */}
                        </div>
                        <div className="">
                          <h1 id="leftTime2" />
                          <p className="bgcolor mb-2">
                            Token {dash.CapingPerT}x Capping (${" "}
                            {dash.MaxIncomeT})
                          </p>
                          <div className="TotalEarnChatMain mt-3">
                            <div className="priceChart">
                              <span className="priceChart_text">
                                $ {dash.EarnAmountT}
                              </span>
                              <div className="slice">
                                <div className="bar" />
                                <div className="fill" />
                              </div>
                            </div>
                          </div>
                          <h2>Status :{dash.EarnPerT} %</h2>
                          {/* <p>Amount :$ {dash.EarnAmountT}</p> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row mt30 dmt-80 g-3">
                <div className="col-md-3">
                  <div className="SellersItem">
                    <div className="SellersText">
                      <h2 className="timetext">
                        <span>
                          <img
                            className="w-100 h-100"
                            src="./assets/images/tokenbuy2.svg"
                            alt=""
                          />
                        </span>
                        Buy Rate
                      </h2>
                      <p className="text-end">{dash?.BuyRate}</p>
                    </div>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="SellersItem">
                    <div className="SellersText">
                      <h2 className="timetext">
                        <span>
                          <img
                            className="w-100 h-100"
                            src="./assets/images/tokenbuy.svg"
                            alt=""
                          />
                        </span>
                        Sell Rate{" "}
                      </h2>
                      <p className="text-end">{dash?.SellRate}</p>
                    </div>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="SellersItem">
                    <div className="SellersText">
                      <h2 className="timetext">
                        <span>
                          <img
                            className="w-100 h-100"
                            src="./assets/images/buysale.svg"
                            alt=""
                          />
                        </span>
                        Total Token Sold
                      </h2>
                      <p className="text-end">{dash?.Total_Sold_Token}</p>
                    </div>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="SellersItem">
                    <div className="SellersText">
                      <h2 className="timetext">
                        <span>
                          <img
                            className="w-100 h-100"
                            src="./assets/images/totaltoken.svg"
                            alt=""
                          />
                        </span>
                        Total Token
                      </h2>
                      <p className="text-end">{dash?.Total_Token}</p>
                    </div>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="SellersItem">
                    <div className="SellersText">
                      <h2 className="timetext">
                        <span>
                          <img
                            className="w-100 h-100"
                            src="./assets/images/Direct-Income.svg"
                            alt=""
                          />
                        </span>
                        Direct Income{" "}
                      </h2>
                      <p className="text-end">
                        $ {dash.Direct_Income} / {dash.Direct_IncomeT} Token
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="SellersItem">
                    <div className="SellersText">
                      <h2 className="timetext">
                        <span>
                          <img
                            className="w-100 h-100"
                            src="./assets/images/matrixincome.svg"
                            alt=""
                          />
                        </span>{" "}
                        Matrix Income{" "}
                      </h2>
                      <p className="text-end">
                        $ {dash.Matrix_Income} / {dash.Matrix_IncomeT} Token
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="SellersItem">
                    <div className="SellersText">
                      <h2 className="timetext">
                        <span>
                          <img
                            className="w-100 h-100 p-2"
                            src="./assets/images/Matrix-CTO-Income.svg"
                            alt=""
                          />
                        </span>
                        Matrix CTO Income
                      </h2>
                      <p className="text-end">
                        $ {dash.Matrix_Cto_Income} / {dash.matrixctoT} Token
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="SellersItem">
                    <div className="SellersText">
                      <h2 className="timetext">
                        <span>
                          <img
                            className="w-100 h-100"
                            src="./assets/images/withincome.svg"
                            alt=""
                          />
                        </span>
                        Withdrawal Income
                      </h2>
                      <p className="text-end">
                        $ {dash?.Withdrawal_Direct_Income} /{" "}
                        {dash?.Withdrawal_Direct_IncomeT} Token
                      </p>
                    </div>
                  </div>
                </div>
                {/* <div class="col-md-3">
                          <div class="SellersItem">
                              <div class="SellersText">
                                  <h2 class="timetext"><span><img class="w-100 h-100" src="./assets/images/Total-Team-Business.svg" alt=""></span> Total Team Business : </h2>
                                  <p class="text-end">0</p>
                              </div>
                          </div>
                      </div> */}
                <div className="col-md-3">
                  <div className="SellersItem">
                    <div className="SellersText">
                      <h2 className="timetext">
                        <span>
                          <img
                            className="w-100 h-100"
                            src="./assets/images/Withdrawal-Amount.svg"
                            alt=""
                          />
                        </span>
                        Withdrawal Amount
                      </h2>
                      <p className="text-end">$ {dash.WithDrawal_Amount}</p>
                    </div>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="SellersItem">
                    <div className="SellersText">
                      <h2 className="timetext">
                        <span>
                          <img
                            className="w-100 h-100"
                            src="./assets/images/Net-Withdrawal-Balance.svg"
                            alt=""
                          />
                        </span>
                        Net Withdrawal Balance
                      </h2>
                      <p className="text-end">$ {dash.Net_Balance}</p>
                    </div>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="SellersItem">
                    <div className="SellersText">
                      <h2 className="timetext">
                        <span>
                          <img
                            className="w-100 h-100"
                            src="./assets/images/Net-Withdrawal-Balance.svg"
                            alt=""
                          />
                        </span>
                        Direct Reward Earning
                      </h2>
                      <p className="text-end">$ {dash.rewardincome}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-3">
              <div className="row gy-2">
                <div className="col-md-4" />
                <div className="col-md-4">
                  <div className="Img_profile">
                    <center>
                      <img src="assets/images/profile.png" className="img_p" />
                    </center>
                  </div>
                </div>
                <div className="zackItem">
                  <div className="profileSection">
                    <h2>User Id:</h2>
                    <p className="mb-0">{user}</p>
                    <div className="SellersItem shadow-none border-0">
                      <div className="SellersText">
                        <h2>Wallet Address:</h2>
                        <p className="text-center" id="walletAddress">
                          {`${walletAddress?.substring(
                            0,
                            6
                          )}...${walletAddress?.substring(
                            walletAddress?.length - 4
                          )}`}
                          <i
                            className="bx bx-copy copyimg"
                            onClick={AddressCopyAddressHandler}
                          />
                        </p>
                      </div>
                    </div>
                    <div className="SellersItem shadow-none border-0">
                      <div className="SellersText">
                        <h2>Your IP Address:</h2>
                        <p className="mb-0">{ip}</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="zackItem mb-1">
                  <div className="profileSection py-3">
                    <h2 className="timetextimg d-flex align-items-center justify-content-between binerincome">
                      <span>
                        <img
                          className="w-100 h-100"
                          src="./assets/images/binaryincome.svg"
                          alt=""
                        />
                      </span>
                      Binary Income
                    </h2>
                    <p className="mb-0 text-end">
                      $ {dash.Binary_Income} / {dash.Binary_IncomeT} Token
                    </p>
                  </div>
                </div>
                <div className="zackItem mb-0">
                  <div className="profileSection py-2">
                    <h2 className="timetextimg d-flex align-items-center justify-content-between binerincome">
                      <span>
                        <img
                          className="w-100 h-100"
                          src="./assets/images/Total-Income.svg"
                          alt=""
                        />
                      </span>
                      Total Income
                    </h2>
                    <p className="mb-0 text-end">
                      {dash.Total_Income} / {dash.Total_Income_Token}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12">
              <div className="row mt30 mb50 g-3">
                <div className="col-md-4">
                  <div className="hotbidsItem maincard p-3 rounded-3 cardscards">
                    <div className="row mx-0 gy-2">
                      <div className="col-12">
                        <div className="card mycard">
                          <div className="card-body popular_nfts ">
                            <div className="d-sm-flex align-items-center justify-content-between nftTimeItem nftteam2nd ">
                              <div className="fs-16 fw-bold text-white text-uppercase">
                                My Package
                                <br />
                                <div className="fs-14 fw-bold text-white text-uppercase my-2">
                                  Current Package-:{dash?.CurrentPackage}
                                </div>
                                <div className="fs-14 fw-bold text-white text-uppercase">
                                  gross Package-:{dash.TotalInvest}
                                </div>
                              </div>
                              <div className="TotalEarnChatMain">
                                {/* <div className="priceChart">
                                  <span className="priceChart_text">
                                    $4398.34
                                  </span>
                                  <div className="slice">
                                    <div className="bar" />
                                    <div className="fill" />
                                  </div>
                                </div> */}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="card mycard2">
                          <div className="card-body ">
                            <div className="card-heading text-white fs-6 fw-bold pb-3 text-uppercase">
                              Team Size
                            </div>
                            <div className="d-flex align-items-center justify-content-between mb-2">
                              <div className="d-flex align-items-center justify-content-between w-100">
                                <div className="fs-14 text-white fw-normal imagetext d-flex align-items-center gap-2">
                                  Left{" "}
                                  <span className="d-flex align-items-center justify-content-center">
                                    <img
                                      src="./assets/images/newimage/download.png"
                                      alt=""
                                    />
                                  </span>
                                </div>
                                <div className="fs-14 text-white fw-normal imagetext d-flex align-items-center gap-2">
                                  {" "}
                                  <span className="d-flex align-items-center justify-content-center">
                                    <img
                                      src="./assets/images/newimage/icon2.png"
                                      alt=""
                                    />
                                  </span>
                                  Right
                                </div>
                              </div>
                            </div>
                            <div className="d-flex align-items-center justify-content-between mb-2">
                              <div className="d-flex align-items-center justify-content-between w-100">
                                <div className="fs-14 text-white fw-normal imagetext d-flex align-items-center gap-2 textimagesM">
                                  <span className="d-flex align-items-center justify-content-center">
                                    <img
                                      src="./assets/images/newimage/icon1.png"
                                      alt=""
                                    />
                                  </span>
                                  Total : {dash?.TotalLeftTeam}{" "}
                                </div>
                                <div className="fs-14 text-white fw-normal imagetext d-flex align-items-center gap-2 textimagesM">
                                  <span className="d-flex align-items-center justify-content-center">
                                    <img
                                      src="./assets/images/newimage/icon1.png"
                                      alt=""
                                    />
                                  </span>
                                  Total : {dash?.TotalRightTeam}
                                </div>
                              </div>
                            </div>
                            <div className="d-flex align-items-center justify-content-between mb-2">
                              <div className="d-flex align-items-center justify-content-between w-100">
                                <div className="fs-14 text-white fw-normal imagetext d-flex align-items-center gap-2 textimagesM">
                                  <span className="d-flex align-items-center justify-content-center">
                                    <img
                                      src="./assets/images/newimage/icon1.png"
                                      alt=""
                                    />
                                  </span>
                                  Active : {dash?.TotalLeftActiveTeam}
                                </div>
                                <div className="fs-14 text-white fw-normal imagetext d-flex align-items-center gap-2 textimagesM">
                                  <span className="d-flex align-items-center justify-content-center">
                                    <img
                                      src="./assets/images/newimage/icon1.png"
                                      alt=""
                                    />
                                  </span>
                                  Active : {dash?.TotalRightActiveTeam}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="card mycard2">
                          <div className="card-body ">
                            <div className="card-heading text-white fs-6 fw-bold pb-3 text-uppercase">
                              Team Business
                            </div>
                            <div className="d-flex align-items-center justify-content-between mb-2">
                              <div className="d-flex align-items-center justify-content-between w-100">
                                <div className="fs-14 text-white fw-normal imagetext d-flex align-items-center gap-2">
                                  Left{" "}
                                  <span className="d-flex align-items-center justify-content-center">
                                    <img
                                      src="./assets/images/newimage/download.png"
                                      alt=""
                                    />
                                  </span>
                                </div>
                                <div className="fs-14 text-white fw-normal imagetext d-flex align-items-center gap-2">
                                  {" "}
                                  <span className="d-flex align-items-center justify-content-center">
                                    <img
                                      src="./assets/images/newimage/icon2.png"
                                      alt=""
                                    />
                                  </span>
                                  Right
                                </div>
                              </div>
                            </div>
                            <div className="d-flex align-items-center justify-content-between mb-2">
                              <div className="d-flex align-items-center justify-content-between w-100">
                                <div className="fs-14 text-white fw-normal imagetext d-flex align-items-center gap-2 textimagesM">
                                  <span className="d-flex align-items-center justify-content-center">
                                    <img
                                      src="./assets/images/newimage/icon1.png"
                                      alt=""
                                    />
                                  </span>
                                  Total : {dash.totalLeftTeamBusiness}
                                </div>
                                <div className="fs-14 text-white fw-normal imagetext d-flex align-items-center gap-2 textimagesM">
                                  <span className="d-flex align-items-center justify-content-center">
                                    <img
                                      src="./assets/images/newimage/icon1.png"
                                      alt=""
                                    />
                                  </span>
                                  Total : {dash.totalRightTeamBusiness}
                                </div>
                              </div>
                            </div>
                            <div className="d-flex align-items-center justify-content-between mb-2">
                              <div className="d-flex align-items-center justify-content-between w-100">
                                <div className="fs-14 text-white fw-normal imagetext d-flex align-items-center gap-2 textimagesM">
                                  <span className="d-flex align-items-center justify-content-center">
                                    <img
                                      src="./assets/images/newimage/icon1.png"
                                      alt=""
                                    />
                                  </span>
                                  Today : {dash?.todayLeftTeamBusiness}
                                </div>
                                <div className="fs-14 text-white fw-normal imagetext d-flex align-items-center gap-2 textimagesM">
                                  <span className="d-flex align-items-center justify-content-center">
                                    <img
                                      src="./assets/images/newimage/icon1.png"
                                      alt=""
                                    />
                                  </span>
                                  Today : {dash?.todayRightTeamBusiness}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="card mycard2">
                          <div className="card-body ">
                            <div className="card-heading text-white fs-6 fw-bold pb-3 text-uppercase">
                              My Referral
                            </div>
                            <div className="d-flex align-items-center justify-content-between mb-2">
                              <div className="d-flex align-items-center justify-content-between w-100">
                                <div className="fs-14 text-white fw-normal imagetext d-flex align-items-center gap-2">
                                  Left{" "}
                                  <span className="d-flex align-items-center justify-content-center">
                                    <img
                                      src="./assets/images/newimage/download.png"
                                      alt=""
                                    />
                                  </span>
                                </div>
                                <div className="fs-14 text-white fw-normal imagetext d-flex align-items-center gap-2">
                                  {" "}
                                  <span className="d-flex align-items-center justify-content-center">
                                    <img
                                      src="./assets/images/newimage/icon2.png"
                                      alt=""
                                    />
                                  </span>
                                  Right
                                </div>
                              </div>
                            </div>
                            <div className="d-flex align-items-center justify-content-between mb-2">
                              <div className="d-flex align-items-center justify-content-between w-100">
                                <div className="fs-14 text-white fw-normal imagetext d-flex align-items-center gap-2 textimagesM">
                                  <span className="d-flex align-items-center justify-content-center">
                                    <img
                                      src="./assets/images/newimage/icon1.png"
                                      alt=""
                                    />
                                  </span>
                                  Total : {dash?.TotalLeftReferral}
                                </div>
                                <div className="fs-14 text-white fw-normal imagetext d-flex align-items-center gap-2 textimagesM">
                                  <span className="d-flex align-items-center justify-content-center">
                                    <img
                                      src="./assets/images/newimage/icon1.png"
                                      alt=""
                                    />
                                  </span>
                                  Total : {dash?.TotalRightReferral}
                                </div>
                              </div>
                            </div>
                            <div className="d-flex align-items-center justify-content-between mb-2">
                              <div className="d-flex align-items-center justify-content-between w-100">
                                <div className="fs-14 text-white fw-normal imagetext d-flex align-items-center gap-2 textimagesM">
                                  <span className="d-flex align-items-center justify-content-center">
                                    <img
                                      src="./assets/images/newimage/icon1.png"
                                      alt=""
                                    />
                                  </span>
                                  Active : {dash?.TotalLeftActiveReferral}
                                </div>
                                <div className="fs-14 text-white fw-normal imagetext d-flex align-items-center gap-2 textimagesM">
                                  <span className="d-flex align-items-center justify-content-center">
                                    <img
                                      src="./assets/images/newimage/icon1.png"
                                      alt=""
                                    />
                                  </span>
                                  Active : {dash?.TotalRightActiveReferral}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="card myteamcard">
                    <div className="card-header py-3 bgmain text-uppercase">
                      MY Matrix Income
                    </div>
                    <div className="cardteam bg-transparent py-2 row mx-0 align-items-center">
                      <div className="col-4">
                        <h6>Level 1</h6>
                      </div>
                      <div className="col-4">
                        <h6 className="fw-bold">{dash.Matrix_Team1}</h6>
                      </div>
                      <div className="col-4">
                        <button className="btn btn1 px-4 py-2 text-white fw-bold">
                          $ {dash.Matrix_Income1}
                        </button>
                      </div>
                    </div>
                    <div className="cardteam bg-transparent py-2 row mx-0 align-items-center">
                      <div className="col-4">
                        <h6>Level 2</h6>
                      </div>
                      <div className="col-4">
                        <h6 className="fw-bold">{dash.Matrix_Team2}</h6>
                      </div>
                      <div className="col-4">
                        <button className="btn btn1 px-4 py-2 text-white fw-bold">
                          $ {dash.Matrix_Income2}
                        </button>
                      </div>
                    </div>
                    <div className="cardteam bg-transparent py-2 row mx-0 align-items-center">
                      <div className="col-4">
                        <h6>Level 3</h6>
                      </div>
                      <div className="col-4">
                        <h6 className="fw-bold">{dash.Matrix_Team3}</h6>
                      </div>
                      <div className="col-4">
                        <button className="btn btn1 px-4 py-2 text-white fw-bold">
                          $ {dash.Matrix_Income3}
                        </button>
                      </div>
                    </div>
                    <div className="cardteam bg-transparent py-2 row mx-0 align-items-center">
                      <div className="col-4">
                        <h6>Level 4</h6>
                      </div>
                      <div className="col-4">
                        <h6 className="fw-bold">{dash.Matrix_Team4}</h6>
                      </div>
                      <div className="col-4">
                        <button className="btn btn1 px-4 py-2 text-white fw-bold">
                          $ {dash.Matrix_Income4}
                        </button>
                      </div>
                    </div>
                    <div className="cardteam bg-transparent py-2 row mx-0 align-items-center">
                      <div className="col-4">
                        <h6>Level 5</h6>
                      </div>
                      <div className="col-4">
                        <h6 className="fw-bold">{dash.Matrix_Team5}</h6>
                      </div>
                      <div className="col-4">
                        <button className="btn btn1 px-4 py-2 text-white fw-bold">
                          $ {dash.Matrix_Income5}
                        </button>
                      </div>
                    </div>
                    <div className="cardteam bg-transparent py-2 row mx-0 align-items-center">
                      <div className="col-4">
                        <h6>Level 6</h6>
                      </div>
                      <div className="col-4">
                        <h6 className="fw-bold">{dash.Matrix_Team6}</h6>
                      </div>
                      <div className="col-4">
                        <button className="btn btn1 px-4 py-2 text-white fw-bold">
                          $ {dash.Matrix_Income6}
                        </button>
                      </div>
                    </div>
                    <div className="cardteam bg-transparent py-2 row mx-0 align-items-center">
                      <div className="col-4">
                        <h6>Level 7</h6>
                      </div>
                      <div className="col-4">
                        <h6 className="fw-bold">{dash.Matrix_Team7}</h6>
                      </div>
                      <div className="col-4">
                        <button className="btn btn1 px-4 py-2 text-white fw-bold">
                          $ {dash.Matrix_Income7}
                        </button>
                      </div>
                    </div>
                    <div className="cardteam bg-transparent py-2 row mx-0 align-items-center">
                      <div className="col-4">
                        <h6>Level 8</h6>
                      </div>
                      <div className="col-4">
                        <h6 className="fw-bold">{dash.Matrix_Team8}</h6>
                      </div>
                      <div className="col-4">
                        <button className="btn btn1 px-4 py-2 text-white fw-bold">
                          $ {dash.Matrix_Income8}
                        </button>
                      </div>
                    </div>
                    <div className="cardteam bg-transparent py-2 row mx-0 align-items-center">
                      <div className="col-4">
                        <h6>Level 9</h6>
                      </div>
                      <div className="col-4">
                        <h6 className="fw-bold">{dash.Matrix_Team9}</h6>
                      </div>
                      <div className="col-4">
                        <button className="btn btn1 px-4 py-2 text-white fw-bold">
                          $ {dash.Matrix_Income9}
                        </button>
                      </div>
                    </div>
                    <div className="cardteam bg-transparent py-2 row mx-0 align-items-center">
                      <div className="col-4">
                        <h6>Level 10</h6>
                      </div>
                      <div className="col-4">
                        <h6 className="fw-bold">{dash.Matrix_Team10}</h6>
                      </div>
                      <div className="col-4">
                        <button className="btn btn1 px-4 py-2 text-white fw-bold">
                          $ {dash.Matrix_Income10}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="card myteamcard">
                    <div className="card-header py-3 bgmain">
                      INCOME SUMMARY
                    </div>
                    <div className="row mx-0">
                      <div className="col-12">
                        <ul className="p-0 m-0 listcricle">
                          <li className="d-flex my-3 p-1">
                            <div className="circle-small">
                              <div className="text">
                                $ {dash.Binary_Income}{" "}
                              </div>
                              <svg>
                                <circle className="bg" cx={40} cy={40} r={37} />
                                <circle
                                  className="progress two"
                                  cx={40}
                                  cy={40}
                                  r={37}
                                />
                              </svg>
                            </div>
                            <div className="row w-100 align-items-center">
                              <div className="col-md-9">
                                <div className="me-2">
                                  <h6 className="mb-2 fontss text-uppercase">
                                    Binary Income
                                  </h6>
                                </div>
                              </div>
                            </div>
                            <div className="resize-triggers">
                              <div className="expand-trigger">
                                <div />
                              </div>
                              <div className="contract-trigger" />
                            </div>
                          </li>
                          <li className="d-flex mb-3 pb-1">
                            <div className="circle-small">
                              <div className="text">
                                $ {dash.Direct_Income}{" "}
                              </div>
                              <svg>
                                <circle className="bg" cx={40} cy={40} r={37} />
                                <circle
                                  className="progress one"
                                  cx={40}
                                  cy={40}
                                  r={37}
                                />
                              </svg>
                            </div>
                            <div className="row w-100 align-items-center">
                              <div className="col-md-9">
                                <div className="me-2">
                                  <h6 className="mb-2 fontss text-uppercase">
                                    Direct Income
                                  </h6>
                                </div>
                              </div>
                            </div>
                            <div className="resize-triggers">
                              <div className="expand-trigger">
                                <div />
                              </div>
                              <div className="contract-trigger" />
                            </div>
                          </li>
                          <li className="d-flex mb-3 pb-1">
                            <div className="circle-small">
                              <div className="text">
                                $ {dash.Matrix_Income}{" "}
                              </div>
                              <svg>
                                <circle className="bg" cx={40} cy={40} r={37} />
                                <circle
                                  className="progress two"
                                  cx={40}
                                  cy={40}
                                  r={37}
                                />
                              </svg>
                            </div>
                            <div className="row w-100 align-items-center">
                              <div className="col-md-9">
                                <div className="me-2">
                                  <h6 className="mb-2 fontss text-uppercase">
                                    Matrix Income
                                  </h6>
                                </div>
                              </div>
                            </div>
                            <div className="resize-triggers">
                              <div className="expand-trigger">
                                <div />
                              </div>
                              <div className="contract-trigger" />
                            </div>
                          </li>
                          <li className="d-flex mb-3 pb-1">
                            <div className="circle-small">
                              <div className="text">
                                $ {dash.Matrix_Cto_Income}
                              </div>
                              <svg>
                                <circle className="bg" cx={40} cy={40} r={37} />
                                <circle
                                  className="progress two"
                                  cx={40}
                                  cy={40}
                                  r={37}
                                />
                              </svg>
                            </div>
                            <div className="row w-100 align-items-center">
                              <div className="col-md-9">
                                <div className="me-2">
                                  <h6 className="mb-2 fontss text-uppercase">
                                    Matrix CTO Income
                                  </h6>
                                </div>
                              </div>
                            </div>
                            <div className="resize-triggers">
                              <div className="expand-trigger">
                                <div />
                              </div>
                              <div className="contract-trigger" />
                            </div>
                          </li>
                          <li className="d-flex mb-3 pb-1">
                            <div className="circle-small">
                              <div className="text">
                                $ {dash?.Withdrawal_Direct_Income}
                              </div>
                              <svg>
                                <circle className="bg" cx={40} cy={40} r={37} />
                                <circle
                                  className="progress two"
                                  cx={40}
                                  cy={40}
                                  r={37}
                                />
                              </svg>
                            </div>
                            <div className="row w-100 align-items-center">
                              <div className="col-md-9">
                                <div className="me-2">
                                  <h6 className="mb-2 fontss text-uppercase">
                                    Withdrawal Income
                                  </h6>
                                </div>
                              </div>
                            </div>
                            <div className="resize-triggers">
                              <div className="expand-trigger">
                                <div />
                              </div>
                              <div className="contract-trigger" />
                            </div>
                          </li>

                          <li className="d-flex mb-3 pb-1">
                            <div className="circle-small">
                              <div className="text">$ 0</div>
                              <svg>
                                <circle className="bg" cx={40} cy={40} r={37} />
                                <circle
                                  className="progress two"
                                  cx={40}
                                  cy={40}
                                  r={37}
                                />
                              </svg>
                            </div>
                            <div className="row w-100 align-items-center">
                              <div className="col-md-9">
                                <div className="me-2">
                                  <h6 className="mb-2 fontss text-uppercase">
                                    Direct Reward Earning
                                  </h6>
                                </div>
                              </div>
                            </div>
                            <div className="resize-triggers">
                              <div className="expand-trigger">
                                <div />
                              </div>
                              <div className="contract-trigger" />
                            </div>
                          </li>

                          {/* <li className="d-flex mb-3 pb-1">
                            <div className="circle-small">
                              <div className="text">{dash.Total_Income} $ </div>
                              <svg>
                                <circle className="bg" cx={40} cy={40} r={37} />
                                <circle
                                  className="progress two"
                                  cx={40}
                                  cy={40}
                                  r={37}
                                />
                              </svg>
                            </div>
                            <div className="row w-100 align-items-center">
                              <div className="col-md-9">
                                <div className="me-2">
                                  <h6 className="mb-2 fontss text-uppercase">
                                    Total Income
                                  </h6>
                                </div>
                              </div>
                            </div>
                            <div className="resize-triggers">
                              <div className="expand-trigger">
                                <div />
                              </div>
                              <div className="contract-trigger" />
                            </div>
                          </li> */}
                          {/* <li className="d-flex mb-3 pb-1">
                            <div className="circle-small">
                              <div className="text">
                                {dash.WithDrawal_Amount} $
                              </div>
                              <svg>
                                <circle className="bg" cx={40} cy={40} r={37} />
                                <circle
                                  className="progress one"
                                  cx={40}
                                  cy={40}
                                  r={37}
                                />
                              </svg>
                            </div>
                            <div className="row w-100 align-items-center">
                              <div className="col-md-9">
                                <div className="me-2">
                                  <h6 className="mb-2 fontss text-uppercase">
                                    Withdrawal Amount
                                  </h6>
                                </div>
                              </div>
                            </div>
                            <div className="resize-triggers">
                              <div className="expand-trigger">
                                <div />
                              </div>
                              <div className="contract-trigger" />
                            </div>
                          </li> */}
                          {/* <li className="d-flex mb-3 pb-1">
                            <div className="circle-small">
                              <div className="text">{dash.Net_Balance} $</div>
                              <svg>
                                <circle className="bg" cx={40} cy={40} r={37} />
                                <circle
                                  className="progress one"
                                  cx={40}
                                  cy={40}
                                  r={37}
                                />
                              </svg>
                            </div>
                            <div className="row w-100 align-items-center">
                              <div className="col-md-9">
                                <div className="me-2">
                                  <h6 className="mb-2 fontss text-uppercase">
                                    Net Withdrawal Balance
                                  </h6>
                                </div>
                              </div>
                            </div>
                            <div className="resize-triggers">
                              <div className="expand-trigger">
                                <div />
                              </div>
                              <div className="contract-trigger" />
                            </div>
                          </li> */}
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <div className="col-md-6">
                  <div className="hotbidsItem maincard p-0 rounded-3">
                    <div className="col-12 border-bottom bgmain">
                      <h4 className="textmatrix W-100 fw-bold fs-16 maincardheader">
                        MATRIX BUSINESS SUMMARY
                      </h4>
                    </div>
                    <div className="row mx-0 g-3 p-3">
                      <div className="col-6 leftside">
                        <h1 className="text-white">$ 0</h1>
                        <div className="row mx-0 mt-3">
                          <div className="col-sm-6">
                            <ul className="list mb-0 ps-0">
                              <li className="d-flex align-items-center gap-3 mb-3">
                                <div className="samllbox px-3 py-1">$5</div>
                                <div className="textsmall text-white">$0</div>
                              </li>
                              <li className="d-flex align-items-center gap-3 mb-3">
                                <div className="samllbox px-3 py-1">$10</div>
                                <div className="textsmall text-white">$0</div>
                              </li>
                              <li className="d-flex align-items-center gap-3 mb-3">
                                <div className="samllbox px-3 py-1">$25</div>
                                <div className="textsmall text-white">$0</div>
                              </li>
                              <li className="d-flex align-items-center gap-3 mb-3">
                                <div className="samllbox px-3 py-1">$50</div>
                                <div className="textsmall text-white">$0</div>
                              </li>
                              <li className="d-flex align-items-center gap-3 mb-3">
                                <div className="samllbox px-3 py-1">$100</div>
                                <div className="textsmall text-white">$0</div>
                              </li>
                              <li className="d-flex align-items-center gap-3 mb-3">
                                <div className="samllbox px-3 py-1">$250</div>
                                <div className="textsmall text-white">$0</div>
                              </li>
                              <li className="d-flex align-items-center gap-3 mb-3">
                                <div className="samllbox px-3 py-1">$500</div>
                                <div className="textsmall text-white">$0</div>
                              </li>
                            </ul>
                          </div>
                          <div className="col-sm-6">
                            <ul className="list mb-0 ps-0">
                              <li className="d-flex align-items-center gap-3 mb-3">
                                <div className="samllbox px-3 py-1">$1000</div>
                                <div className="textsmall text-white">$0</div>
                              </li>
                              <li className="d-flex align-items-center gap-3 mb-3">
                                <div className="samllbox px-3 py-1">$2500</div>
                                <div className="textsmall text-white">$0</div>
                              </li>
                              <li className="d-flex align-items-center gap-3 mb-3">
                                <div className="samllbox px-3 py-1">$5000</div>
                                <div className="textsmall text-white">$0</div>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6 rightside d-none d-sm-block">
                        <div className="card-image mt-5">
                          {" "}
                          <img
                            className="mt-4"
                            src="./assets/images/charts.png"
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div> */}
                <div className="col-md-12">
                  <div className="card myteamcard h-100 adjustmentsection indextable">
                    <div className="card-header py-3 text-uppercase bgmain border-bottom fs-6">
                      buy and Sell Details
                    </div>
                    <div className="card bg-transparent mb-0 py-2 border-0 shadow-none">
                      <div className="card-body p-0 \">
                        <div className="row mx-0">
                          <div className="col-sm-12">
                            <div className="mt-md-3 table_main table-responsive ">
                              {/* <table role="table">
                                <thead className="border_bottom">
                                  <tr role="row">
                                    <th
                                      className="text-white"
                                      colSpan={1}
                                      role="columnheader">
                                      S.No
                                    </th>
                                    <th
                                      className="text-white"
                                      colSpan={1}
                                      role="columnheader">
                                      Buy Rate
                                    </th>
                                    <th
                                      className="text-white"
                                      colSpan={1}
                                      role="columnheader">
                                      Sell Rate
                                    </th>
                                    <th
                                      className="text-white"
                                      colSpan={1}
                                      role="columnheader">
                                      USD
                                    </th>
                                    <th
                                      className="text-white"
                                      colSpan={1}
                                      role="columnheader">
                                      Token.No
                                    </th>
                                    <th
                                      className="text-white"
                                      colSpan={1}
                                      role="columnheader">
                                      Remark
                                    </th>
                                    <th
                                      className="text-white"
                                      colSpan={1}
                                      role="columnheader">
                                      Txn
                                    </th>
                                    <th
                                      className="text-white"
                                      colSpan={1}
                                      role="columnheader">
                                      Date
                                    </th>
                                  </tr>
                                </thead>
                                <tbody role="rowgroup">
                                  <tr className="table_tr" role="row">
                                    <td className="text-white" role="cell">
                                      1
                                    </td>
                                    <td className="text-white" role="cell">
                                      0.02307905
                                    </td>
                                    <td className="text-white" role="cell">
                                      0.02307794
                                    </td>
                                    <td className="text-white" role="cell">
                                      20
                                    </td>
                                    <td className="text-white" role="cell">
                                      779.9656
                                    </td>
                                    <td className="text-white" role="cell">
                                      <button className="btn btn-theme1 text-white px-3 py-1 bgmain shadow-none fs-12">
                                        Buy
                                      </button>
                                    </td>
                                    <td className="text-white" role="cell">
                                      EEA4B429-24A9-4019
                                    </td>
                                    <td className="text-white" role="cell">
                                      24/01/2024, 11:30 PM
                                    </td>
                                  </tr>
                                  <tr className="table_tr" role="row">
                                    <td className="text-white" role="cell">
                                      2
                                    </td>
                                    <td className="text-white" role="cell">
                                      0.02307794
                                    </td>
                                    <td className="text-white" role="cell">
                                      0.02308348
                                    </td>
                                    <td className="text-white" role="cell">
                                      20
                                    </td>
                                    <td className="text-white" role="cell">
                                      779.7784
                                    </td>
                                    <td className="text-white" role="cell">
                                      <button className="btn btn-theme1 text-white px-3 py-1 sellbtn shadow-none fs-12">
                                        Sell
                                      </button>
                                    </td>
                                    <td className="text-white" role="cell">
                                      846C8777-E914-47CF{" "}
                                    </td>
                                    <td className="text-white" role="cell">
                                      25/01/2024, 09:25 AM
                                    </td>
                                  </tr>
                                  <tr className="table_tr" role="row">
                                    <td className="text-white" role="cell">
                                      3
                                    </td>
                                    <td className="text-white" role="cell">
                                      0.02308237
                                    </td>
                                    <td className="text-white" role="cell">
                                      0.02308126
                                    </td>
                                    <td className="text-white" role="cell">
                                      20
                                    </td>
                                    <td className="text-white" role="cell">
                                      779.8534
                                    </td>
                                    <td className="text-white" role="cell">
                                      <button className="btn btn-theme1 text-white px-3 py-1 bgmain shadow-none fs-12">
                                        Buy
                                      </button>
                                    </td>
                                    <td className="text-white" role="cell">
                                      70330065-26BF-4D8B
                                    </td>
                                    <td className="text-white" role="cell">
                                      25/01/2024, 09:25 AM
                                    </td>
                                  </tr>
                                  <tr className="table_tr" role="row">
                                    <td className="text-white" role="cell">
                                      4
                                    </td>
                                    <td className="text-white" role="cell">
                                      0.02308126
                                    </td>
                                    <td className="text-white" role="cell">
                                      0.02308016
                                    </td>
                                    <td className="text-white" role="cell">
                                      20
                                    </td>
                                    <td className="text-white" role="cell">
                                      779.8906
                                    </td>
                                    <td className="text-white" role="cell">
                                      <button className="btn btn-theme1 text-white px-3 py-1 sellbtn shadow-none fs-12">
                                        Sell
                                      </button>
                                    </td>
                                    <td className="text-white" role="cell">
                                      44B57B2D-96F5-4992
                                    </td>
                                    <td className="text-white" role="cell">
                                      25/01/2024, 09:24 AM
                                    </td>
                                  </tr>
                                  <tr className="table_tr" role="row">
                                    <td className="text-white" role="cell">
                                      5
                                    </td>
                                    <td className="text-white" role="cell">
                                      0.02308016
                                    </td>
                                    <td className="text-white" role="cell">
                                      0.02307905
                                    </td>
                                    <td className="text-white" role="cell">
                                      20
                                    </td>
                                    <td className="text-white" role="cell">
                                      779.9281
                                    </td>
                                    <td className="text-white" role="cell">
                                      <button className="btn btn-theme1 text-white px-3 py-1 bgmain shadow-none fs-12">
                                        Buy
                                      </button>
                                    </td>
                                    <td className="text-white" role="cell">
                                      C4EB9E20-B23B-4301
                                    </td>
                                    <td className="text-white" role="cell">
                                      25/01/2024, 09:24 AM
                                    </td>
                                  </tr>
                                  <tr className="table_tr" role="row">
                                    <td className="text-white" role="cell">
                                      6
                                    </td>
                                    <td className="text-white" role="cell">
                                      0.02307905
                                    </td>
                                    <td className="text-white" role="cell">
                                      0.02307794
                                    </td>
                                    <td className="text-white" role="cell">
                                      20
                                    </td>
                                    <td className="text-white" role="cell">
                                      779.9656
                                    </td>
                                    <td className="text-white" role="cell">
                                      <button className="btn btn-theme1 text-white px-3 py-1  shadow-none fs-12 sellbtn">
                                        Sell
                                      </button>
                                    </td>
                                    <td className="text-white" role="cell">
                                      EEA4B429-24A9-4019{" "}
                                    </td>
                                    <td className="text-white" role="cell">
                                      24/01/2024, 11:30 PM
                                    </td>
                                  </tr>
                                  <tr className="table_tr" role="row">
                                    <td className="text-white" role="cell">
                                      7
                                    </td>
                                    <td className="text-white" role="cell">
                                      0.02307794
                                    </td>
                                    <td className="text-white" role="cell">
                                      0.02307683
                                    </td>
                                    <td className="text-white" role="cell">
                                      20
                                    </td>
                                    <td className="text-white" role="cell">
                                      780.0031
                                    </td>
                                    <td className="text-white" role="cell">
                                      <button className="btn btn-theme1 text-white px-3 py-1 bgmain shadow-none fs-12">
                                        Buy
                                      </button>
                                    </td>
                                    <td className="text-white" role="cell">
                                      32EC8AE4-A8DA-4900
                                    </td>
                                    <td className="text-white" role="cell">
                                      24/01/2024, 11:19 PM
                                    </td>
                                  </tr>
                                  <tr className="table_tr" role="row">
                                    <td className="text-white" role="cell">
                                      8
                                    </td>
                                    <td className="text-white" role="cell">
                                      0.02307683
                                    </td>
                                    <td className="text-white" role="cell">
                                      0.02307571
                                    </td>
                                    <td className="text-white" role="cell">
                                      20
                                    </td>
                                    <td className="text-white" role="cell">
                                      780.041
                                    </td>
                                    <td className="text-white" role="cell">
                                      <button className="btn btn-theme1 text-white px-3 py-1  shadow-none fs-12 sellbtn">
                                        Sell
                                      </button>
                                    </td>
                                    <td className="text-white" role="cell">
                                      0D77C099-1A77-4F49
                                    </td>
                                    <td className="text-white" role="cell">
                                      24/01/2024, 11:03 PM
                                    </td>
                                  </tr>
                                  <tr className="table_tr" role="row">
                                    <td className="text-white" role="cell">
                                      9
                                    </td>
                                    <td className="text-white" role="cell">
                                      0.02307571
                                    </td>
                                    <td className="text-white" role="cell">
                                      0.0230746
                                    </td>
                                    <td className="text-white" role="cell">
                                      20
                                    </td>
                                    <td className="text-white" role="cell">
                                      780.0785
                                    </td>
                                    <td className="text-white" role="cell">
                                      <button className="btn btn-theme1 text-white px-3 py-1 bgmain shadow-none fs-12">
                                        Buy
                                      </button>
                                    </td>
                                    <td className="text-white" role="cell">
                                      01CD1A91-9FAD-44B8
                                    </td>
                                    <td className="text-white" role="cell">
                                      24/01/2024, 09:53 PM
                                    </td>
                                  </tr>
                                  <tr className="table_tr" role="row">
                                    <td className="text-white" role="cell">
                                      10
                                    </td>
                                    <td className="text-white" role="cell">
                                      0.0230746
                                    </td>
                                    <td className="text-white" role="cell">
                                      0.02307349
                                    </td>
                                    <td className="text-white" role="cell">
                                      20
                                    </td>
                                    <td className="text-white" role="cell">
                                      780.1161
                                    </td>
                                    <td className="text-white" role="cell">
                                      <button className="btn btn-theme1 text-white px-3 py-1 shadow-none fs-12 sellbtn">
                                        Sell
                                      </button>
                                    </td>
                                    <td className="text-white" role="cell">
                                      0706DEF7-76FE-4797{" "}
                                    </td>
                                    <td className="text-white" role="cell">
                                      24/01/2024, 09:52 PM
                                    </td>
                                  </tr>
                                </tbody>
                              </table> */}
                              {/* <Sell_Rate_Chart /> */}
                              <BuysellDashboard />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
