import React, { useEffect, useState } from "react";
import ShareTable from "../Components/Table/Table";
import { API } from "../API/Api";
import { useSelector } from "react-redux";

const columns = [
  {
    dataField: "Number",
    text: "S.No",
    sort: false,
  },
  {
    dataField: "Buy_Rate",
    text: "Buy Rate",
    sort: false,
  },
  {
    dataField: "Sell_Rate",
    text: "Sell Rate",
    sort: false,
  },
  {
    dataField: "USD",
    text: "USD",
    sort: false,
  },
  {
    dataField: "Token_No",
    text: "Token.No",
    sort: false,
  },
  {
    dataField: "Remark",
    text: "Remark",
    sort: false,
  },
  {
    dataField: "Txn",
    text: "Txn",
    sort: false,
  },
  {
    dataField: "Date",
    text: "Date",
    sort: false,
  },
];

const row = [
  {
    Number: 1,
    Buy_Rate: "0.02307905",
    Sell_Rate: "0.02307794",
    USD: "20",
    Token_No: "779.9656",
    Remark: (
      <button class="btn btn-theme1 text-white px-3 py-1 bgmain shadow-none fs-12">
        Buy
      </button>
    ),
    Txn: "EEA4B429-24A9-4019",
    Date: "24/01/2024, 11:30 PM",
  },
  {
    Number: 2,
    Buy_Rate: "0.02307905",
    Sell_Rate: "0.02307794",
    USD: "20",
    Token_No: "779.9656",
    Remark: (
      <button class="btn btn-theme1 text-white px-3 py-1 bgmain shadow-none fs-12">
        Buy
      </button>
    ),
    Txn: "EEA4B429-24A9-4019",
    Date: "24/01/2024, 11:30 PM",
  },
  {
    Number: 3,
    Buy_Rate: "0.02307905",
    Sell_Rate: "0.02307794",
    USD: "20",
    Token_No: "779.9656",
    Remark: (
      <button class="btn btn-theme1 text-white px-3 py-1 sellbtn shadow-none fs-12">
        Sell
      </button>
    ),
    Txn: "EEA4B429-24A9-4019",
    Date: "24/01/2024, 11:30 PM",
  },
];

export default function BuysellDashboard() {
  const [dataArray, setdataArray] = useState([]);
  // const user = useSelector((state) => state.UserAuth.userId);
  const jwt = useSelector((state) => state.Auth.jwt_token);
  const [currentPageSize, setCurrentPageSize] = useState(10);
  
  const Sell_Rate_Chart_API = async () => {
    try {
      let responce = await API?.get(`/buysellrequestHistory`, {
        headers: {
          Authorization: `${jwt}`,
          "Content-Type": "application/json", 
        },
      });
      responce = responce?.data?.data[0];
      // console.log("responce", responce);
      let arr = [];
      responce.forEach((item, index) => {
        arr.push({
          Number: item.row,
          Buy_Rate: item?.BuyRate,
          Sell_Rate: item?.sellRate,
          USD: item?.USD,
          Token_No: item?.TotalCoin,
          Remark: (
            <button
              className={`btn btn-theme1 text-white px-3 py-1 ${
                item.Remark == "Buy" ? "bgmain" : "sellbtn"
              } shadow-none fs-12`}>
              {item?.Remark}
            </button>
          ),
          Txn: item?.Txn,
          Date: item?.dd,
        });
      });

      setdataArray(arr);
    } catch (e) {
      console.log("Error While calling Referrer API", e);
    }
  };

  useEffect(() => {
    Sell_Rate_Chart_API();
  }, []);


  const handlePageSizeChange = (size) => {
    setCurrentPageSize(size);
  };


  return (
    <div className="table-container col-12">
      <div className="card bg-transparent mb-0 py-2" style={{ width: "100%" }}>
        <div className="card-body" style={{ width: "100%" }}>
          <div className="row mx-0" style={{ width: "100%" }}>
            <ShareTable
               columns={columns}
               Data={dataArray}
               defaultPageSize={currentPageSize}
               pageSizeOptions={['10', '50', '100']}
               onPageSizeChange={handlePageSizeChange}
               style={{ width: "100%" }}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
