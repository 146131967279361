import React from 'react'

function MatrixHoverNode({data}) {
  return (
    <div className="dropdown-content  ">
      <table className="tables " cellpadding="0px" cellspacing="0px">
        <tbody className=''>
         
          <tr className="fgtybmd">
            <td className="table_heading">Sponsor :</td>
            <td className="grtydfbc" colspan="3">
              {data?.Sponsor}
            </td>
          </tr>
          <tr className="fgtybmd">
            <td className="table_heading">Activation Date :</td>
            <td className="grtydfbc" colspan="3">
              {data?.Activation_date}
            </td>
          </tr>
          <tr className="fgtybmd">
            <td className="table_heading">Downline :</td>
            <td className="grtydfbc" colspan="3">
              {data?.downline}
            </td>
          </tr>
          <tr className="fgtybmd">
            <td className="table_heading">Package Amount :</td>
            <td className="grtydfbc" colspan="3">
              {data?.package_amount}
            </td>
          </tr>
          <tr className="fgtybmd">
            <td className="table_heading">Latest Amount :</td>
            <td className="grtydfbc" colspan="3">
              {data?.latest}
            </td>
          </tr>
          <tr className="fgtybmd">
            <td className="table_heading">Gross Amount :</td>
            <td className="grtydfbc" colspan="3">
              {data?.gross}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  )
}

export default MatrixHoverNode