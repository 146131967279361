import React from "react";
import BuysellDashboard from "./BuysellDashboard";

export default function Sell_Rate_Chart() {
  return (
    <div>
      <div className="page-wrapper px-0">
        <div className="page-content adjustmentsection">
          <div className="row">
            <div className="col-12">
              <div className="d-flex align-items-center justify-content-between">
                <div className="heading text-white fw-bold fs-4 mb-3">
                  Buy / Sell Rate Chart
                </div>
                <div className="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
                  <div className="breadcrumb-title pe-3">Buy</div>
                  <div className="ps-3">
                    <nav aria-label="breadcrumb">
                      <ol className="breadcrumb mb-0 p-0">
                        <li className="breadcrumb-item">
                          <a href="">
                            <i className="bx bx-home-alt text-white" />
                          </a>
                        </li>
                        <li
                          className="breadcrumb-item active"
                          aria-current="page">
                          Sell Rate Chart
                        </li>
                      </ol>
                    </nav>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12" style={{ width: "100%" }}>
              <BuysellDashboard />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
