import React, { useEffect, useState } from "react";
import { API } from "../API/Api";
import ShareTable from "../Components/Table/Table";
import { useSelector } from "react-redux";

const columns = [
  {
    dataField: "Number",
    text: "S.No",
    sort: false,
  },

  {
    dataField: "Date",
    text: "Date",
    sort: false,
  },
  {
    dataField: "Amount",
    text: "Amount",
    sort: false,
  },
  {
    dataField: "Purchased_Token",
    text: "Purchased Token",
    sort: false,
  },

  {
    dataField: "Buy_Rate",
    text: "Buy Rate",
    sort: false,
  },
  {
    dataField: "Transaction_ID",
    text: "Transaction ID",
    sort: false,
  },
];

const row = [
  {
    Number: "1",
    Purchased_Token: "12345",
    Date: "12-06-2024",
    Buy_Rate: "150",
    Amount: "100",
    Transaction_ID:"1234"
  },
  {
    Number: "2",
    Purchased_Token: "12345",
    Date: "12-06-2024",
    Buy_Rate: "150",
    Amount: "100",
    Transaction_ID:"1234"
  },
  {
    Number: "3",
    Purchased_Token: "12345",
    Date: "12-06-2024",
    Buy_Rate: "150",
    Amount: "100",
    Transaction_ID:"1234"
  },
];

export default function Buy_Token_Report() {
  const [dataArray, setdataArray] = useState([]);
  const [currentPageSize, setCurrentPageSize] = useState(10);
  const user = useSelector((state) => state.Auth.userId);
  const jwt = useSelector((state) => state.Auth.jwt_token);
  const [fromDateFilter, setFromDateFilter] = useState("");
  const [toDateFilter, setToDateFilter] = useState("");


  const Buy_Token_Report_API = async () => {
    try {
      let responce = await API?.post(
        `/BuyTokenHistory`,
        {
          uid: user,
          fdate: fromDateFilter,
          tdate: toDateFilter,
          "txnid": ""

        },
        {
          headers: {
            Authorization: `${jwt}`,
            "Content-Type": "application/json", 
          },
        }
      );
      responce = responce?.data?.data;
      // console.log("BuyTokenHistory", responce);

      let arr = [];
      responce.forEach((item, index) => {
        arr.push({
          Number: item?.row,
          Purchased_Token: item?.Coinvalue,
          Date: item?.edate1,
          Buy_Rate: item?.coin_rate,
          Amount: item?.usdamount,
          Transaction_ID:item?.txn
        });
      });

      setdataArray(arr);
    } catch (e) {
      console.log("Error While calling Referrer API", e);
    }
  };

  useEffect(() => {
    Buy_Token_Report_API();
  }, []);

  const handlePageSizeChange = (size) => {
    setCurrentPageSize(size);
  };

  const resetFilters = () => {
    setFromDateFilter("");
    setToDateFilter("");
    Buy_Token_Report_API();
  };

  return (
    <div>
      <div className="page-wrapper">
        <div className="page-content adjustmentsection">
          {/*breadcrumb*/}
          <div className="d-flex align-items-center justify-content-between py-3">
            <div className="fs-18 text-white fw-bold">Buy Token Report</div>
            <div className="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
              <div className="breadcrumb-title pe-3">Buy</div>
              <div className="ps-3">
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb mb-0 p-0">
                    <li className="breadcrumb-item">
                      <a href="javascript:;">
                        <i className="bx bx-home-alt text-white" />
                      </a>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      But Token Report
                    </li>
                  </ol>
                </nav>
              </div>
            </div>
          </div>
          <div className="row" style={{ marginLeft: 10 }}>
            <div className="col-md-3">
              <label className="text-white fs-12">Enter From Date</label>
              <input
                type="date"
                name="from_date"
                id="from_date"
                className="form-control shadow-none bgmain"
                placeholder="Enter From Date"
                onChange={(e) => setFromDateFilter(e.target.value)}

              />
            </div>
            <br />
            <br />
            <div className="col-md-3">
              <label className="text-white fs-12">Enter To Date</label>
              <input
                type="date"
                name="to_date"
                id="to_date"
                className="form-control bgmain shadow-none"
                onChange={(e) => setToDateFilter(e.target.value)}
              />
            </div>
            <br />
            <br />
            <div className="col-md-auto col-3">
              <input
                type="Find"
                name="to_date"
                defaultValue="Find"
                className="btn btn-primary mt_5 bgmain border-0 px-3 shadow-none"
                onClick={Buy_Token_Report_API}
              />
            </div>
            <div className="col-md-auto col-3">
              <input
                type="Reset"
                name="to_date"
                defaultValue="Reset"
                className="btn btn-primary mt_5 bgmain border-0 px-3 shadow-none"
                onClick={resetFilters}

              />
            </div>
          </div>
          <br />
          <div className="row mx-0">
            <div className="col-12">
              <div className="card bg-transparent mb-0 py-2">
                <div className="card-body">
                  <div className="row mx-0">
                    <ShareTable
                      columns={columns}
                      Data={dataArray}
                      defaultPageSize={currentPageSize}
                      pageSizeOptions={['10', '50', '100']}
                      onPageSizeChange={handlePageSizeChange}
                      style={{ width: "100%" }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/*end row*/}
        </div>
      </div>
    </div>
  );
}
