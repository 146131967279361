import React, { useEffect, useState } from "react";
import { API } from "../API/Api";
import ShareTable from "../Components/Table/Table";
import { useSelector } from "react-redux";

const columns = [
  {
    dataField: "Number",
    text: "S.No",
    sort: false,
  },

  {
    dataField: "User_ID",
    text: "User ID",
    sort: false,
  },
  {
    dataField: "Wallet",
    text: "Wallet",
    sort: false,
  },
  {
    dataField: "Registration_Date",
    text: "Registration_Date",
    sort: false,
  },

  {
    dataField: "Level",
    text: "Level",
    sort: false,
  },
  {
    dataField: "Status",
    text: "Status",
    sort: false,
  },
  {
    dataField: "Latest_Package",
    text: "Latest Package",
    sort: false,
  },
  {
    dataField: "Gross_Package",
    text: "Gross Package",
    sort: false,
  },
  {
    dataField: "Activation_Date",
    text: "Activation Date",
    sort: false,
  },
];

export default function Level_Details() {
  const [dataArray, setdataArray] = useState([]);
  const user = useSelector((state) => state.Auth.userId);
  const jwt = useSelector((state) => state.Auth.jwt_token);
  const [StatusFilter, setStatusFilter] = useState(2);
  const [LevelFilter, setLevelFilter] = useState(0);
  const [currentPageSize, setCurrentPageSize] = useState(10);

  const Level_Details_API = async () => {
    try {
      let responce = await API?.post(
        `/levelDetails`,
        {
          uid: user,
          fdate: "",
          tdate: "",
          status: StatusFilter,
          position: 0,
          level: LevelFilter,
        },
        {
          headers: {
            Authorization: `${jwt}`,
            "Content-Type": "application/json",
          },
        }
      );
      responce = responce?.data?.data[0];
      let arr = [];
      responce.forEach((item, index) => {
        arr.push({
          Number: index + 1,
          Registration_Date: item?.registrationDate,
          User_ID: item?.uid,
          Level: item?.leveltype,
          Wallet: `${item?.wallet?.substring(0, 6)}...${item?.wallet?.substring(
            item?.wallet?.length - 4
          )}`,
          Status: (
            <button
              type="button"
              className={`btn1 active_btn ${
                item.status == "Active" ? "green" : "red"
              }`}>
              {item?.status}
            </button>
          ),
          Latest_Package: item?.Latest,
          Gross_Package: item?.Gross,
          Activation_Date:
            item?.activationDate == null ? "Null" : item?.activationDate,
        });
      });

      setdataArray(arr);
    } catch (e) {
      console.log("Error While calling Referrer API", e);
    }
  };

  const handlePageSizeChange = (size) => {
    setCurrentPageSize(size);
  };

  const handleChange_Level = (value) => {
    setLevelFilter(value);
  };

  const handleChange_Status = (value) => {
    setStatusFilter(value);
  };

  useEffect(() => {
    Level_Details_API();
  }, []);

  return (
    <div>
      <div className="page-wrapper">
        <div className="page-content adjustmentsection">
          {/*breadcrumb*/}
          <div className="row mx-0  py-3">
            <div className="col-12">
              <div className="d-flex align-items-center justify-content-between">
                <div className="fs-18 text-white fw-bold">Level Details</div>
                <div className="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
                  <div className="breadcrumb-title pe-3">Level Details</div>
                  <div className="ps-3">
                    <nav aria-label="breadcrumb">
                      <ol className="breadcrumb mb-0 p-0">
                        <li className="breadcrumb-item">
                          <a href="javascript:;">
                            <i className="bx bx-home-alt text-white" />
                          </a>
                        </li>
                        <li
                          className="breadcrumb-item active"
                          aria-current="page">
                          Level Details
                        </li>
                      </ol>
                    </nav>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row mx-0 mb-3">
            <div className="col-md-3">
              <label className="text-white fs-14">Status</label>
              <select
                className="form-control shadow-none bgmain"
                id="status"
                style={{ color: "#fff" }}
                onChange={(e) => handleChange_Status(e.target.value)}>
                <option value={2} style={{ color: "#fff" }}>
                  All
                </option>
                <option value={1} style={{ color: "#fff" }}>
                  Active
                </option>
                <option value={0} style={{ color: "#fff" }}>
                  In-Active
                </option>
              </select>
            </div>
            <br />
            <br />
            <div className="col-md-3">
              <label className="text-white fs-14">Level</label>
              <select
                className="form-control text-white shadow-none bgmain"
                id="status"
                style={{ color: "#fff" }}
                onChange={(e) => handleChange_Level(e.target.value)}>
                <option value={0} style={{ color: "#fff" }}>
                  All
                </option>
                <option value={1} style={{ color: "#fff" }}>
                  Level 1
                </option>
                <option value={2} style={{ color: "#fff" }}>
                  Level 2
                </option>
                <option value={3} style={{ color: "#fff" }}>
                  Level 3
                </option>
                <option value={4} style={{ color: "#fff" }}>
                  Level 4
                </option>
                <option value={5} style={{ color: "#fff" }}>
                  Level 5
                </option>
                <option value={6} style={{ color: "#fff" }}>
                  Level 6
                </option>
                <option value={7} style={{ color: "#fff" }}>
                  Level 7
                </option>
                <option value={8} style={{ color: "#fff" }}>
                  Level 8
                </option>
                <option value={9} style={{ color: "#fff" }}>
                  Level 9
                </option>
                <option value={10} style={{ color: "#fff" }}>
                  Level 10
                </option>
              </select>
            </div>
            <br />
            <br />
            <div className="col-md-auto mt-1">
              <input
                type="submit"
                name="to_date"
                defaultValue="search"
                onClick={Level_Details_API}
                className="btn btn-primary mt_5 bgmain border-0 px-3 shadow-none w-auto"
              />
            </div>
          </div>
          <div className="row mx-0">
            <div className="col-12">
              <div className="card bg-transparent mb-0 py-2">
                <div className="card-body">
                  <div className="row mx-0">
                    <ShareTable
                      columns={columns}
                      Data={dataArray}
                      defaultPageSize={currentPageSize}
                      pageSizeOptions={["10", "50", "100"]}
                      onPageSizeChange={handlePageSizeChange}
                      style={{ width: "100%" }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/*end row*/}
        </div>
      </div>
    </div>
  );
}
