import React, { useEffect, useState } from "react";
import ShareTable from "../Components/Table/Table";
import { API } from "../API/Api";
import { useSelector } from "react-redux";

const columns = [
  {
    dataField: "Number",
    text: "S.No",
    sort: false,
  },

  {
    dataField: "User_ID",
    text: "User ID",
    sort: false,
  },
  {
    dataField: "From_Id",
    text: "From Id",
    sort: false,
  },

  {
    dataField: "Income",
    text: "Income(USD)",
    sort: false,
  },
  {
    dataField: "Income_Token",
    text: "	Income(Token)",
    sort: false,
  },
  // {
  //   dataField: "On_Amount",
  //   text: "On Amount",
  //   sort: false,
  // },
  {
    dataField: "Activation_Date",
    text: "Date",
    sort: false,
  },
  {
    dataField: "Remark",
    text: "Remark",
    sort: false,
  },
];

const row = [
  {
    Number: "1",
    Registration_Date: "12-06-12",
    User_ID: "@1234",
    Income: "$500",
    From_Id: "12345",
    Remark: (
      <button type="button" class="btn1 active_btn green">
        Active
      </button>
    ),
    Income_Token: "12",
    On_Amount: "50k",
    Level: "2",

    Activation_Date: "12-07-2024",
  },
  {
    Number: "2",
    Registration_Date: "12-06-12",
    User_ID: "@1234",
    Income: "$500",
    Level: "4",

    From_Id: "12345",
    Remark: (
      <button type="button" class="btn1 active_btn green">
        Active
      </button>
    ),
    Income_Token: "12",
    On_Amount: "50k",
    Activation_Date: "12-07-2024",
  },
  {
    Number: "3",
    Registration_Date: "12-06-12",
    User_ID: "@1234",
    Income: "$500",
    From_Id: "12345",
    Level: "6",

    Remark: (
      <button type="button" class="btn1 active_btn red">
        Inactive
      </button>
    ),
    Income_Token: "12",
    On_Amount: "50k",
    Activation_Date: "12-07-2024",
  },
];

export default function Withdrawal_Direct_Earning() {
  const [dataArray, setdataArray] = useState([]);
  const user = useSelector((state) => state.Auth.userId);
  const jwt = useSelector((state) => state.Auth.jwt_token);
  const [currentPageSize, setCurrentPageSize] = useState(10);

  const Withdrawal_Direct_Earning_API = async () => {
    try {
      let responce = await API?.post(
        `/withdrawalDirectIncome`,
        {
          uid: user,
          fdate: "",
          tdate: "",
        },
        {
          headers: {
            Authorization: `${jwt}`,
            "Content-Type": "application/json",
          },
        }
      );
      responce = responce?.data?.data[0];
      // console.log("withdrawalDirectIncome", responce);

      let arr = [];
      responce.forEach((item, index) => {
        arr.push({
          Number: item?.row,
          Level: item?.level,
          Registration_Date: item?.registrationDate,
          User_ID: item?.uid,
          Income_Token: item?.token_amount,
          Income: item?.income,
          From_Id: item?.fromid,
          On_Amount: item?.cto,
          Activation_Date: item?.dd == null ? "Null" : item?.dd,
          Remark: item?.remark,
          // Remark: (
          //   <button type="button" className={`btn1 active_btn ${item?.status==1 ? "green":"red"}`}>
          //     {item?.status==1 ?"Active":"InActive"}
          //   </button>
          // ),
        });
      });

      setdataArray(arr);
    } catch (e) {
      console.log("Error While calling Referrer API", e);
    }
  };

  const handlePageSizeChange = (size) => {
    setCurrentPageSize(size);
  };

  useEffect(() => {
    Withdrawal_Direct_Earning_API();
  }, []);
  return (
    <div>
      <div className="page-wrapper">
        <div className="page-content adjustmentsection">
          {/*breadcrumb*/}
          <div className="row mx-0  py-3">
            <div className="col-12">
              <div className="d-flex align-items-center justify-content-between">
                <div className="fs-18 text-white fw-bold">
                  Withdrawal Direct Earning Report
                </div>
                <div className="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
                  <div className="breadcrumb-title pe-3">Withdrawal Direct</div>
                  <div className="ps-3">
                    <nav aria-label="breadcrumb">
                      <ol className="breadcrumb mb-0 p-0">
                        <li className="breadcrumb-item">
                          <a href="javascript:;">
                            <i className="bx bx-home-alt text-white" />
                          </a>
                        </li>
                        <li
                          className="breadcrumb-item active"
                          aria-current="page">
                          Withdrawal Direct Earning Report
                        </li>
                      </ol>
                    </nav>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row mx-0">
            <div className="col-12">
              <div className="card bg-transparent mb-0 py-2">
                <div className="card-body">
                  <div className="row mx-0">
                    <ShareTable
                      columns={columns}
                      Data={dataArray}
                      defaultPageSize={currentPageSize}
                      pageSizeOptions={["10", "50", "100"]}
                      onPageSizeChange={handlePageSizeChange}
                      style={{ width: "100%" }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/*end row*/}
        </div>
      </div>
    </div>
  );
}
