import React, { useEffect, useState } from "react";
import ShareTable from "../Components/Table/Table";
import { API } from "../API/Api";
import { useSelector } from "react-redux";

const columns = [
  {
    dataField: "Number",
    text: "S.No",
    sort: false,
  },
  {
    dataField: "Date",
    text: "Date",
    sort: false,
  },
  {
    dataField: "Transaction_ID",
    text: "Transaction ID",
    sort: false,
  },
  {
    dataField: "Hash_ID",
    text: "Hash ID",
    sort: false,
  },
  {
    dataField: "Amount",
    text: "Amount",
    sort: false,
  },
];

const row = [
  {
    Number: "-",
    Date: "-",
    Transaction_ID: "-",
    Hash_ID: "-",
    Amount: "-",
  },
  {
    Number: "-",
    Date: "-",
    Transaction_ID: "-",
    Hash_ID: "-",
    Amount: "-",
  },
  {
    Number: "-",
    Date: "-",
    Transaction_ID: "-",
    Hash_ID: "-",
    Amount: "-",
  },
];

export default function Fund_Report() {
  const [dataArray, setdataArray] = useState([]);
  const [currentPageSize, setCurrentPageSize] = useState(10);

  const user = useSelector((state) => state.Auth.userId);
  const jwt = useSelector((state) => state.Auth.jwt_token);

  const Fund_Report_API = async () => {
    try {
      let responce = await API?.post(
        `/Deposite_FundByContractHistory`,
        {
          uid: user,
        },
        {
          headers: {
            Authorization: `${jwt}`,
            "Content-Type": "application/json",
          },
        }

      );
      responce = responce?.data?.data;
      // console.log("Deposite_FundByContractHistory",responce);
      let arr = [];
      responce.forEach((item, index) => {
        arr.push({
          Number: item?.row,
          Date: item?.edate1,
          Transaction_ID: item?.txnid,
          Hash_ID: (
            <a
              href={
                item.CoinType === "BNB"
                  ? `https://bscscan.com/tx/${item.txn}`
                  : `https://polygonscan.com/tx/${item.txn}`
              }
              target="_blank"
              rel="noopener noreferrer"
            >
              View Txn
            </a>
          ),
          Amount: item?.usd,
        });
      });

      setdataArray(arr);
    } catch (e) {
      console.log("Error While calling Referrer API", e);
    }
  };

  useEffect(() => {
    Fund_Report_API();
  }, []);

  const handlePageSizeChange = (size) => {
    setCurrentPageSize(size);
  };

  return (
    <div>
      <div className="page-wrapper">
        <div className="page-content adjustmentsection">
          {/*breadcrumb*/}
          <div className="row">
            <div className="col-12">
              <div className="d-flex align-items-center justify-content-between">
                <div
                  className="heading text-white fw-bold fs-18 mb-3"
                  style={{ fontWeight: "700" }}
                >
                  Fund Report
                </div>
                <div className="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
                  <div className="breadcrumb-title pe-3">Fund Report</div>
                  <div className="ps-3">
                    <nav aria-label="breadcrumb">
                      <ol className="breadcrumb mb-0 p-0">
                        <li className="breadcrumb-item">
                          <a href="">
                            <i className="bx bx-home-alt" />
                          </a>
                        </li>
                        <li
                          className="breadcrumb-item active"
                          aria-current="page"
                        >
                          Fund Report
                        </li>
                      </ol>
                    </nav>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12">
              <div className="card bg-transparent mb-0 py-2">
                <div className="card-body">
                  <div className="row mx-0">
                    <ShareTable
                      columns={columns}
                      Data={dataArray}
                      defaultPageSize={currentPageSize}
                      pageSizeOptions={['10', '50', '100']}
                      onPageSizeChange={handlePageSizeChange}
                      style={{ width: "100%" }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/*end breadcrumb*/}
          {/*end row*/}
        </div>
      </div>
    </div>
  );
}
