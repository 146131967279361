import React from "react";

export default function Footer() {
  return (
    <div>
      <>
        <div className="overlay toggle-icon" />
        <a href="javaScript:;" className="back-to-top">
          <i className="bx bxs-up-arrow-alt" />
        </a>
        <footer className="page-footer footer">
          <p className="mb-0">
            Copyright © wyzzkwyscale 2024. All right reserved.
          </p>
        </footer>
      </>
    </div>
  );
}
