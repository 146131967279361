import { Route, Routes } from "react-router-dom";
import "./App.css";
import Footer from "./Components/Footer/Footer";
import Header from "./Components/Header/Header";
import TopHeader from "./Components/Header/TopHeader";
import DashBoard from "./Components/Home/DashBoard";
import Adjustment from "./Pages/Adjustment";
import Sell_Rate_Chart from "./Pages/Sell_Rate_Chart";
import Add_Fund from "./Pages/Add_Fund";
import Fund_Report from "./Pages/Fund_Report";
import Activate_Upgrade from "./Pages/Activate_Upgrade";
import Activate_Upgrade_History from "./Pages/Activate_Upgrade_History";
import Buy_Token from "./Pages/Buy_Token";
import Buy_Token_Report from "./Pages/Buy_Token_Report";
import TodayDownlineReport from "./Pages/TodayDownlineReport";
import TotalDownlineReport from "./Pages/TotalDownlineReport";
import Sell_Token from "./Pages/Sell_Token";
import Sell_Token_Report from "./Pages/Sell_Token_Report";
import Auto_Sell_Token_Report from "./Pages/Auto_Sell_Token_Report";
import Direct_User_List from "./Pages/Direct_User_List";
import Level_Details from "./Pages/Level_Details";
import Downline from "./Pages/Downline";
import Matrix_Downline from "./Pages/Matrix_Downline";
import Direct_Earning_Income from "./Pages/Direct_Earning_Income";
import Binary_Earning_Report from "./Pages/Binary_Earning_Report";
import Matrix_Earning from "./Pages/Matrix_Earning";
import Matrix_CTO_Earning from "./Pages/Matrix_CTO_Earning";
import Withdrawal_Direct_Earning from "./Pages/Withdrawal_Direct_Earning";
import Withdrawal from "./Pages/Withdrawal";
import Withdrawal_History from "./Pages/Withdrawal_History";
import Tree_View from "./Pages/Tree View/Tree_View";
import LogIn from "./Components/LogIn/LogIn";
import { useEffect, useState } from "react";
import Registration from "./Components/LogIn/Registration";
import { Toaster } from "react-hot-toast";
import Matrix_Tree from "./Pages/Matrix_Tree/Matrix_Tree";
import DirectRewardBusiness from "./Pages/DirectRewardBusiness";
import PrivateRoutes from "./Utils/PrivateRoutes";
import NewTree from "./Pages/New Tree/NewTree";
import Matrixnewtree from "./Pages/New Tree/Matrixnewtree";
import ColorGame from "./Components/ColorGame/ColorGame";
import GameToWithrawalWallet from "./Pages/GameToWithrawalWallet";
import WithrawalToGameWallet from "./Pages/WithrawalToGameWallet";
import GameWalletHistory from "./Pages/GameWalletHistory";
function App() {
  // useEffect(() => {
  //   document.addEventListener("contextmenu", function (e) {
  //     e.preventDefault();
  //     debugger;
  //   });

  //   document.onkeydown = function (e) {
  //     if (
  //       (e.ctrlKey && (e.keyCode === 73 || e.keyCode === 85)) ||
  //       e.keyCode === 123
  //     ) {
  //       return false;
  //     }
  //   };
  // }, []);

  const [path, setpath] = useState("");
  useEffect(() => {
    let interval = setInterval(() => {
      let URL = window.location.pathname;
      setpath(URL);
    }, 100);
    return () => clearInterval(interval);
  }, []);

  return (
    <div className="wrapper App">
      <Toaster />
      {path === "/" || path === "/Registration" ? (
        <></>
      ) : (
        <>
          <TopHeader />
          <Header />
        </>
      )}
      <Routes>
        <Route path="/" element={<LogIn />} />
        <Route path="/Registration" element={<Registration />} />
        <Route element={<PrivateRoutes />}>
          <Route path="/DashBoard" element={<DashBoard />} />
          <Route path="/Adjustment" element={<Adjustment />} />
          <Route path="/Sell_Rate_Chart" element={<Sell_Rate_Chart />} />
          <Route path="/Add_Fund" element={<Add_Fund />} />
          <Route path="/Fund_Report" element={<Fund_Report />} />
          <Route path="/Activate_Upgrade" element={<Activate_Upgrade />} />
          <Route
            path="/Activate_Upgrade_History"
            element={<Activate_Upgrade_History />}
          />
          <Route path="/Buy_Token" element={<Buy_Token />} />
          <Route path="/Buy_Token_Report" element={<Buy_Token_Report />} />
          <Route path="/Sell_Token" element={<Sell_Token />} />
          <Route path="/Sell_Token_Report" element={<Sell_Token_Report />} />
          <Route
            path="/Auto_Sell_Token_Report"
            element={<Auto_Sell_Token_Report />}
          />
          {/* <Route path="/Tree_View" element={<Tree_View />} /> */}
          <Route path="/Tree_View" element={<NewTree />} />
          <Route path="/Matrix_Tree" element={<Matrixnewtree />} />
          <Route
            path="/TodayDownlineReport"
            element={<TodayDownlineReport />}
          />
          <Route
            path="/TotalDownlineReport"
            element={<TotalDownlineReport />}
          />

          <Route path="/Direct_User_List" element={<Direct_User_List />} />
          <Route path="/Level_Details" element={<Level_Details />} />
          <Route path="/Downline" element={<Downline />} />
          <Route path="/Matrix_Downline" element={<Matrix_Downline />} />
          {/* <Route path="/Matrix_Tree" element={<Matrix_Tree />} /> */}
          <Route
            path="/Direct_Earning_Income"
            element={<Direct_Earning_Income />}
          />
          <Route
            path="/Binary_Earning_Report"
            element={<Binary_Earning_Report />}
          />
          <Route path="/Matrix_Earning" element={<Matrix_Earning />} />
          <Route path="/Matrix_CTO_Earning" element={<Matrix_CTO_Earning />} />
          <Route
            path="/Withdrawal_Direct_Earning"
            element={<Withdrawal_Direct_Earning />}
          />
          <Route path="/Withdrawal" element={<Withdrawal />} />
          <Route path="/Withdrawal_History" element={<Withdrawal_History />} />
          <Route
            path="/DirectRewardBusiness"
            element={<DirectRewardBusiness />}
          />
          <Route path="/ColorGame" element={<ColorGame />} />
          <Route path="/GameToWithrawalWallet" element={<GameToWithrawalWallet />} />
          <Route path="/WithrawalToGameWallet" element={<WithrawalToGameWallet />} />
          <Route path="/GameWalletHistory" element={<GameWalletHistory />} />
        </Route>
      </Routes>
      {path == "/" || path == "/Registration" ? (
        <></>
      ) : (
        <>
          <Footer />
        </>
      )}
    </div>
  );
}

export default App;
