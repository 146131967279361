import React from "react";
import { useSelector } from "react-redux";

export default function TopHeader() {
  const user = useSelector((state) => state.Auth.userId);
  const status = useSelector((state) => state.Auth.status);

  return (
    <div>
      <header className="d_none">
        <div className="topbar d-flex align-items-center">
          <nav className="navbar navbar-expand">
            <div className="topbar-logo-header">
              <div className="">
                <img
                  src="assets/images/logo.png"
                  className="logo"
                  alt="logo icon"
                />
              </div>
            </div>
            <div className="Id_Profile">
              <h6 className="six">
                ID:-
                {status === "Active" ? (
                  <img src="assets/images/id_green.png" className="id_active" />
                ) : (
                  <img src="assets/images/id_red.png" className="id_Inactive" />
                )}{" "}
                {user}
              </h6>{" "}
              &nbsp;&nbsp;&nbsp;&nbsp;
              <a>
                <img src="assets/images/profile.png" width="40px" />
              </a>
            </div>
          </nav>
        </div>
      </header>
    </div>
  );
}
