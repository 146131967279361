import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { UpdateAuth } from "../Redux/AuthSlice";
import { API } from "../API/Api";
import toast from "react-hot-toast";
import { loadWeb3 } from "../API/contract";
import DOMPurify from "dompurify";

export default function Sell_Token() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector((state) => state.Auth.userId);
  const jwt = useSelector((state) => state.Auth.jwt_token);
  let [Amount, setAmount] = useState(0);
  const [NetWalletBal, setNetWalletBal] = useState(0);
  const [SelectType, setSelectType] = useState("Self Coin");
  const [dashData, setdashData] = useState([]);
  let [connected, setconnected] = useState("Wallet is locked");
  let [spiner, setspiner] = useState(false);
  const [TokenAmount, setTokenAmount] = useState("");
  const [Wallet_Address, setWallet_Address] = useState("");

  const SellWalletType = async (Selectedvalue) => {
    try {
      setSelectType(Selectedvalue);

      let responce = await API?.get(`userDetails?uid=${user}`, {
        headers: {
          Authorization: `${jwt}`,
          "Content-Type": "application/json",
        },
      });
      // console.log("responce", responce);
      responce = responce.data.data[0][0];
       console.log("getUserDetails", responce);

       if (Selectedvalue == "Self Coin") {
        setNetWalletBal(responce.Net_Token);
      } else if (Selectedvalue == "Income Coin") {
        setNetWalletBal(responce.Net_Income_Token);
      } else {
        setNetWalletBal(0);
      }
    } catch (e) {
      //  console.log("userDetails error", e);
      // console.log("userDetails error", e.response.data);
      if (
        e.response.data.showableMessage ===
        "Please login to access this resource"
      ) {
        dispatch(UpdateAuth({ isAuth: false, userId: null, jwt_token: null }));
        navigate("/");
      }
    }
  };

  const GetUserDetails = async () => {
    try {
      let responce = await API?.get(`userDetails?uid=${user}`, {
        headers: {
          Authorization: `${jwt}`,
          "Content-Type": "application/json",
        },
      });
      // console.log("responce", responce);
      responce = responce.data.data[0][0];
      // console.log("getUserDetails", responce);
      setdashData(responce);
      setNetWalletBal(responce.Net_Token);
      setWallet_Address(responce.btcaddress);
    } catch (e) {
       console.log("userDetails error", e);
      // console.log("userDetails error", e.response.data);
      if (
        e.response.data.showableMessage ==
        "Please login to access this resource"
      ) {
        dispatch(UpdateAuth({ isAuth: false, userId: null, jwt_token: null }));
        navigate("/");
      }
    }
  };

  const WalletConnected = async () => {
    try {
      let acc = await loadWeb3();

      if (acc == "No Wallet") {
        setconnected("Wallet is locked");
      } else if (acc == "Wrong Network") {
        toast.error(" Wrong Network Please Connect BSC Network");
        setconnected("Wrong Network");
      } else {
        setconnected("Wallet is Connected");
      }
    } catch (e) {
      console.log("Error While WalletConnect", e);
    }
  };

  const CalculateValue = async (val) => {
    const Selvalues = val.replace(/[^0-9]/gi, "").substring(0, 6);
    // const tvalue = parseFloat(Selvalues) + parseFloat(Amount);
    setTokenAmount(Selvalues);
    setAmount(Selvalues * dashData.SellRate);
  };

  const SellToken = async () => {
    if (user === undefined || user === "" || user === null) {
      navigate("/");
    }
    let acc = await loadWeb3();
    if (acc === "No Wallet" || acc === undefined) {
      toast.error("No Wallet");
    } else if (acc === "Wrong Network") {
      //toast.error("Wrong Network Please Connect BSC Scan Network");
      console.log("Wrong Network Please Connect Polygon Scan Network");
    } else {
      if (Wallet_Address.toUpperCase() === acc.toUpperCase()) {
        // if(1>0){
        if (Amount !== 0) {
          if (NetWalletBal < TokenAmount) {
            toast.error("Insufficient Sell Wallet Balance");
          } else {
            setspiner(true);

            try {
              let res = await API.post(
                "SellToken",
                {
                  uid: user,
                  tokenamount: TokenAmount,
                  selltype: SelectType,
                },
                {
                  headers: {
                    Authorization: `${jwt}`,
                    "Content-Type": "application/json",
                  },
                }
              );
              // console.log("sellcoin", res);

              if (res.data.data === "Sell Token Successfully") {
                toast.success("Sell Token Successfull");
                navigate("/dashboard");
                setspiner(false);
              } else {
                toast.error(res.data.data);
                setspiner(false);
              }

              setspiner(false);
            } catch (err) {
              console.log("error while calling fuction ", err);
              if (
                err.response.data.showableMessage ===
                "Please login to access this resource"
              ) {
                dispatch(
                  UpdateAuth({ isAuth: false, userId: null, jwt_token: null })
                );
                navigate("/");
              }
              setspiner(false);
            }
          }
        } else {
          toast.error("Please Enter Amout First");
        }
      } else {
        toast.error("Invalid Wallet address");
      }
    }

    // setloader(false)
  };

  useEffect(() => {
    WalletConnected();
    GetUserDetails();
  }, []);

  return (
    <div>
      <div className="page-wrapper">
        <div className="page-content">
          <div className="row mx-0  py-3">
            <div className="col-12">
              <div className="d-flex align-items-center justify-content-between">
                <div className="fs-18 text-white fw-bold">Sell Token</div>
                <div className="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
                  <div className="breadcrumb-title pe-3">Sell</div>
                  <div className="ps-3">
                    <nav aria-label="breadcrumb">
                      <ol className="breadcrumb mb-0 p-0">
                        <li className="breadcrumb-item">
                          <a href="">
                            <i className="bx bx-home-alt text-white" />
                          </a>
                        </li>
                        <li
                          className="breadcrumb-item active"
                          aria-current="page">
                          Sell Token
                        </li>
                      </ol>
                    </nav>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-3"></div>
            <div className="col-md-6">
              <div className="modal-dialog">
                <div className="modal-content exchange_width">
                  <div className="modal-header exchange_hd w-100 flex-wrap">
                    <h2 className="w-100 py-3 text-center">Sell Token</h2>
                    <div className="d-flex align-items-center justify-content-between w-100 justifymobile">
                      <p className="text-white w-100 text-start">
                        Sell Wallet Balance : {NetWalletBal || 0}
                      </p>
                      <p className="text-white w-100 text-start">
                        Sell Token Rate : {dashData.SellRate}
                      </p>
                    </div>
                  </div>
                  <div className="modal-body">
                    <div
                      className="box box-default table-wrapper ng-pristine ng-valid ng-valid-maxlength mt-0"
                      // ng-submit="withDrawalAmount()"
                    >
                      <div className="panel-body">
                        <div className="row align-items-center justify-content-between">
                          <div className="col-md-5">
                            <label className="text-white">
                              Select Sell Wallet Type
                            </label>
                          </div>
                          <div className="col-md-7">
                            <select
                              className="form-control shadow-none  w-100"
                              id="status"
                              onChange={(e) => SellWalletType(e.target.value)}>
                              <option value="Self Coin">Self Coin Sell Balance</option>
                              <option value="Income Coin">
                                Income Coin Sell Balance
                              </option>
                            </select>
                          </div>
                        </div>
                        <br />
                        <div className="row align-items-center justify-content-between">
                          <div className="col-md-5">
                            <label>Token Amount </label>
                          </div>
                          <div className="col-md-7">
                            <input
                              type="number"
                              placeholder="Token Amount"
                              className="form-control mb-20 w-100 shadow-none"
                              id="amount"
                              value={TokenAmount}
                              onChange={(e) => {
                                CalculateValue(
                                  DOMPurify.sanitize(e.target.value)
                                );
                              }}
                            />
                          </div>
                        </div>
                        <br />
                        <div
                          className="row align-items-center justify-content-between mrset"
                          id="withdrwaltokendiv">
                          <div className="col-md-5">
                            <label>Calculated USD Amount</label>
                          </div>
                          <div className="col-md-7">
                            <input
                              type="Number"
                              className="form-control w-100"
                              id="tokenvalue1"
                              disabled={true}
                              defaultValue={Amount}
                              value={Amount}
                              readOnly
                            />
                          </div>
                        </div>
                        <div className="row align-items-center justify-content-center mx-0">
                          <div className="col-6 row align-items-center justify-content-center">
                            <button
                              className="btn btn-success withdraw_btn w-100 py-3 shadow-none"
                              onClick={() => {
                                SellToken();
                              }}>
                              Sell Token
                            </button>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-12 ">
                            <p className="text-white fs-6 fw-bold text-center py-2 mb-0 notetext">
                              Note: Minimum Sell Token Amount is 10 Token.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/*end row*/}
          <br />
          <br />
        </div>
      </div>
    </div>
  );
}
