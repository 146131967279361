import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { API } from "../API/Api";
import { UpdateAuth } from "../Redux/AuthSlice";

export default function Adjustment() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.Auth.userId);
  const jwt = useSelector((state) => state.Auth.jwt_token);
  const [data, setData] = useState({});



  const AdjustmentAPI = async () => {
    try {
      let responce = await API.post(
        "adjustmentReport",
        {
          uid: user,
        },
        {
          headers: {
            Authorization: `${jwt}`,
            "Content-Type": "application/json",
          },
        }
      );

      responce = responce.data.data[0];
      // console.log("res", responce);

      setData(responce);
    } catch (e) {
      if (
        e.response.data.showableMessage ===
        "Please login to access this resource"
      ) {
        dispatch(UpdateAuth({ isAuth: false, userId: null, jwt_token: null }));
        navigate("/");
      }
    }
  };

  useEffect(() => {
    AdjustmentAPI();
  }, []);

  return (
    <div>
      <div className="page-wrapper px-0">
        <div className="page-content adjustmentsection">
          <div className="row">
            <div className="col-12">
              <div className="heading text-white fw-bold fs-18 mb-3">
                ADJUSTMENT
              </div>
            </div>
            <div className="col-12">
              <div className="card bg-transparent mb-0 py-2">
                <div className="card-body p-0 p-sm-2">
                  <div className="row mx-0 pb-0 gy-3 align-items-center">
                    <div className="col-md-4">
                      <div className="card mb-0 innercard">
                        <div className="card-body">
                          <div className="card-heading fw-bold text-white">
                            Total Investment
                          </div>
                          <div className="text-white subtext">{data?.investamount || 0}</div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="card mb-0 innercard">
                        <div className="card-body">
                          <div className="card-heading fw-bold text-white">
                            Adjustment
                          </div>
                          <div className="text-white subtext">{data?.totaldeduction || 0}</div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="card mb-0 innercard">
                        <div className="card-body">
                          <div className="card-heading fw-bold text-white">
                            Remaining
                          </div>
                          <div className="text-white subtext">{data?.reamingamount || 0}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
