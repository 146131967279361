import React, { useEffect, useState } from "react";
import { UpdateAuth } from "../Redux/AuthSlice";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { API } from "../API/Api";
import { loadWeb3 } from "../API/contract";
import DOMPurify from "dompurify";
import toast from "react-hot-toast";

export default function Withdrawal() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector((state) => state.Auth.userId);
  const jwt = useSelector((state) => state.Auth.jwt_token);

  let [Amount, setAmount] = useState("");
  let [spiner, setspiner] = useState(false);
  const [Wallet_Address, setWallet_Address] = useState("");
  const [loading, setloading] = useState(false);
  const [TopupStatus, setTopupStatus] = useState("0");
  let [USDT, setUSDT] = useState(0);
  let [connected, setconnected] = useState("Wallet is locked");

  const GetUserDetails = async () => {
    try {
      let responce = await API?.get(`userDetails?uid=${user}`, {
        headers: {
          Authorization: `${jwt}`,
          "Content-Type": "application/json",
        },
      });
      // console.log("responce", responce);
      responce = responce.data.data[0][0];
      // console.log("getUserDetails", responce);

      setWallet_Address(responce.btcaddress);
      setTopupStatus(responce.top_up);
      setUSDT(responce.Net_Balance);
    } catch (e) {
      //  console.log("userDetails error", e);
      // console.log("userDetails error", e.response.data);
      if (
        e.response.data.showableMessage ===
        "Please login to access this resource"
      ) {
        dispatch(UpdateAuth({ isAuth: false, userId: null, jwt_token: null }));
        navigate("/");
      }
    }
  };

  const WalletConnected = async () => {
    try {
      let acc = await loadWeb3();

      if (acc === "No Wallet") {
        setconnected("Wallet is locked");
      } else if (acc === "Wrong Network") {
        toast.error(" Wrong Network Please Connect BSC Network");
        setconnected("Wrong Network");
      } else {
        setconnected("Wallet is Connected");
      }
    } catch (e) {
      console.log("Error While WalletConnect", e);
    }
  };

  const CalculateValue = async (val) => {
    const Selvalues = DOMPurify.sanitize(
      val.replace(/[^0-9]/gi, "").substring(0, 6)
    );
    // const tvalue = parseFloat(Selvalues) + parseFloat(Amount);
    setAmount(Selvalues);
  };

  const WithdrawalUser = async (tstatus) => {
    try {
      if (user === undefined || user === "" || user === null) {
        navigate("/");
      }
      let acc = await loadWeb3();
      if (acc === "No Wallet" || acc === undefined) {
        toast.error("No Wallet");
      } else if (acc == "Wrong Network") {
        //toast.error("Wrong Network Please Connect BSC Scan Network");
        console.log("Wrong Network Please Connect Polygon Scan Network");
      } else {
        if (Wallet_Address.toUpperCase() == acc.toUpperCase()) {
          if (Amount !== 0) {
            if (parseFloat(USDT) < parseFloat(Amount)) {
              toast.error("Insufficient USDT Balance");
              setspiner(false);
              return;
            } else {
              setspiner(true);
            }

            if (parseFloat(Amount) < parseFloat(10)) {
              toast.error("You can wihdrawal minimum 10 USDT");
              setspiner(false);
              return;
            } else {
              setspiner(true);
            }

            let res = await API.post(
              "/withdrawal",
              {
                uid: user,
                usd: Amount,
                token: Amount,
                bnb: Amount,
              },
              {
                headers: {
                  Authorization: `${jwt}`,
                  "Content-Type": "application/json",
                },
              }
            );

            // console.log("res-->", res);
            if (res.data.data === "SUCCESSFUL") {
              toast.success("Withdrawal Successful !!!");
              GetUserDetails();
              setAmount(0);
              setspiner(false);
            }

            setspiner(false);
          }
        } else {
          toast.error("Invalid Wallet address");
        }
      }
    } catch (err) {
      // console.log("error while calling fuction sell", err);
      if (
        err.response.data.showableMessage ===
        "Please login to access this resource"
      ) {
        dispatch(UpdateAuth({ isAuth: false, userId: null, jwt_token: null }));
        navigate("/");
      }
      setspiner(false);
    }
    // setloader(false)
  };

  useEffect(() => {
    WalletConnected();
    // USDTBalance();
    GetUserDetails();
  }, []);

  return (
    <div>
      <div className="page-wrapper">
        <div className="page-content">
          <div className="row mx-0  py-3">
            <div className="col-12">
              <div className="d-flex align-items-center justify-content-between">
                <div className="fs-18 text-white fw-bold">Withdrawal</div>
                <div className="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
                  <div className="breadcrumb-title pe-3">Withdrawal</div>
                  <div className="ps-3">
                    <nav aria-label="breadcrumb">
                      <ol className="breadcrumb mb-0 p-0">
                        <li className="breadcrumb-item">
                          <a href="">
                            <i className="bx bx-home-alt text-white" />
                          </a>
                        </li>
                        <li
                          className="breadcrumb-item active"
                          aria-current="page">
                          Withdrawal
                        </li>
                      </ol>
                    </nav>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-3"></div>
            <div className="col-md-6">
              <div className="modal-dialog">
                <div className="modal-content exchange_width">
                  <div className="modal-header exchange_hd w-100 flex-wrap ">
                    <h4 className="w-100 text-center pt-2">
                      Wallet is Connected
                    </h4>
                    <p className="text-white fs-16 text-center w-100">
                      Available Net Balance : {USDT} USDT
                    </p>
                  </div>
                  <div className="modal-body">
                    <form
                      className="box box-default table-wrapper ng-pristine ng-valid ng-valid-maxlength mt-0"
                      ng-submit="withDrawalAmount()">
                      <div className="panel-body">
                        <div className="row align-items-center justify-content-center">
                          <div className="col-md-4 col-6">
                            <label>Enter USD Amount</label>
                          </div>
                          <div className="col-md-8 col-6">
                            <input
                              type="text"
                              className="form-control mb-20  shadow-none"
                              id="amountInput"
                              // defaultValue={0}
                              min={1}
                              step={1}
                              maxLength={4}
                              defaultValue={Amount}
                              value={Amount}
                              onChange={(e) => CalculateValue(e.target.value)}
                              style={{ width: "90%" }}
                            />
                          </div>
                        </div>
                        <div className="row align-items-center justify-content-center mx-0 pt-3">
                          <div className="col-6 row align-items-center justify-content-center">
                            {parseInt(TopupStatus) === 1 && (
                              <button
                                className="btn btn-success withdraw_btn w-100 py-3 shadow-none"
                                type="button"
                                onClick={() => {
                                  WithdrawalUser();
                                }}>
                                Withdrawal
                              </button>
                            )}
                          </div>
                          <div className="col-12 ">
                            <p className="text-white fs-6 fw-bold text-center py-2 mb-0 notetext">
                              Note: You can withdrawal minimum 10 $.
                            </p>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
