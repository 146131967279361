import React, { useEffect, useState } from "react";
import ShareTable from "../Components/Table/Table";
import { API } from "../API/Api";
import { useSelector } from "react-redux";

const columns = [
  {
    dataField: "Number",
    text: "S.No",
    sort: false,
  },

  {
    dataField: "User_ID",
    text: "User ID",
    sort: false,
  },
  {
    dataField: "Amount",
    text: "Amount",
    sort: false,
  },

  {
    dataField: "Upline_Bonus",
    text: "Upline Bonus",
    sort: false,
  },
  {
    dataField: "Net_Payable_Amount",
    text: "Net Payable Amount",
    sort: false,
  },
  {
    dataField: "Activation_Date",
    text: "Date",
    sort: false,
  },
  {
    dataField: "Remark",
    text: "Remark",
    sort: false,
  },
  {
    dataField: "Payment_Mode",
    text: "Payment Mode",
    sort: false,
  },
];

const row = [
  {
    Number: "1",
    Registration_Date: "12-06-12",
    User_ID: "@1234",
    Net_Payable_Amount: "36000",
    Amount: "12345",
    Remark: (
      <button type="button" class="btn1 active_btn green">
        Active
      </button>
    ),
    Upline_Bonus: "12",
    Payment_Mode: "PayPal",
    Level: "2",

    Activation_Date: "12-07-2024",
  },
  {
    Number: "2",
    Registration_Date: "12-06-12",
    User_ID: "@1234",
    Net_Payable_Amount: "36000",
    Level: "4",

    Amount: "12345",
    Remark: (
      <button type="button" class="btn1 active_btn green">
        Active
      </button>
    ),
    Upline_Bonus: "12",
    Payment_Mode: "PayPal",
    Activation_Date: "12-07-2024",
  },
  {
    Number: "3",
    Registration_Date: "12-06-12",
    User_ID: "@1234",
    Net_Payable_Amount: "36000",
    Amount: "12345",
    Level: "6",

    Remark: (
      <button type="button" class="btn1 active_btn red">
        Inactive
      </button>
    ),
    Upline_Bonus: "12",
    Payment_Mode: "PayPal",
    Activation_Date: "12-07-2024",
  },
];

export default function Withdrawal_History() {
  const [dataArray, setdataArray] = useState([]);
  const user = useSelector((state) => state.Auth.userId);
  const jwt = useSelector((state) => state.Auth.jwt_token);
  const [currentPageSize, setCurrentPageSize] = useState(10);

  const Withdrawal_History_API = async () => {
    try {
      let responce = await API?.post(
        `/withdrawalReport`,
        {
          uid: user,
          fdate: "",
          tdate: "",
        },
        {
          headers: {
            Authorization: `${jwt}`,
            "Content-Type": "application/json",
          },
        }
      );
      responce = responce?.data?.data[0];
      // console.log("withdrawalReport", responce);

      let arr = [];
      responce.forEach((item, index) => {
        arr.push({
          Net_Payable_Amount: item?.paymode,
          Amount: item?.Request_amount,
          Upline_Bonus: +item.admincharge + +item.sidamount,
          Payment_Mode: item?.ss,
          Number: item?.row,
          Level: item?.level,
          Registration_Date: item?.registrationDate,
          User_ID: item?.uid,
          Income_Token: item?.cappingamount,
          Income: item?.income,
          From_Id: item?.fromid,
          On_Amount: item?.cto,
          Activation_Date:
            item?.request_date == null ? "Null" : item?.request_date,
          Remark: (
            <a href={`https://bscscan.com/tx/${item.txn}`} target="_blank">
              View Txn
            </a>
          ),
          // Remark: (
          //   <button
          //     type="button"
          //     className={`btn1 active_btn ${
          //       item?.status == 1 ? "green" : "red"
          //     }`}
          //   >
          //     {item?.status == 1 ? "Active" : "InActive"}
          //   </button>
          // ),
        });
      });

      setdataArray(arr);
    } catch (e) {
      console.log("Error While calling Referrer API", e);
    }
  };

  const handlePageSizeChange = (size) => {
    setCurrentPageSize(size);
  };

  useEffect(() => {
    Withdrawal_History_API();
  }, []);
  return (
    <div>
      <div className="page-wrapper">
        <div className="page-content adjustmentsection">
          {/*breadcrumb*/}
          <div className="row mx-0  py-3">
            <div className="col-12">
              <div className="d-flex align-items-center justify-content-between">
                <div className="fs-18 text-white fw-bold">
                  Withdrawal History
                </div>
                <div className="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
                  <div className="breadcrumb-title pe-3">History</div>
                  <div className="ps-3">
                    <nav aria-label="breadcrumb">
                      <ol className="breadcrumb mb-0 p-0">
                        <li className="breadcrumb-item">
                          <a href="javascript:;">
                            <i className="bx bx-home-alt text-white" />
                          </a>
                        </li>
                        <li
                          className="breadcrumb-item active"
                          aria-current="page">
                          Withdrawal History
                        </li>
                      </ol>
                    </nav>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row mx-0">
            <div className="col-12">
              <div className="card bg-transparent mb-0 py-2">
                <div className="card-body">
                  <div className="row mx-0">
                    <ShareTable
                      columns={columns}
                      Data={dataArray}
                      defaultPageSize={currentPageSize}
                      pageSizeOptions={["10", "50", "100"]}
                      onPageSizeChange={handlePageSizeChange}
                      style={{ width: "100%" }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/*end row*/}
        </div>
      </div>
    </div>
  );
}
